import { useNavigate  } from "react-router-dom";

const Back = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <div className='back-link'><button className="btn-icon" onClick={() => navigate(-1)}><img src={require('./../assets/images/back-icon.png')} alt="Back" /></button></div> */}
      <div className='back-link'><button className="btn-icon" onClick={() => navigate('/')}><img src={require('./../assets/images/back-icon.png')} alt="Back" /></button></div>
    </>
);
};
  
export default Back;