import React, {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserService from '../services/UserService';

const Header = () => {

  console.log('JWT Token ',UserService.getToken());

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  return (
    <header>
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/"><img src={require('./../assets/images/logo.png')} alt="Amberoon Logo" /></Link>
          <div className='header-right'>
            <span>Hello {UserService.getWelcomeName()}!</span>
            <img src={require('./../assets/images/user-icon.png')} alt="User Icon" height="40" className='ms-3' />
            <span className='login-logout'>
              <button onClick={handleLogout}><img src={require('./../assets/images/logout-icon.png')} alt="Logout" height="30" /></button>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
