import React, {useState, useEffect} from 'react';
import { Container, Tab, Tabs} from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import ApplicationList from './ApplicationList';
import Users from './Users';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const Home = () => {

  const [mToken, setMToken] = useState(null);
  const [privilegeReadAppList, setPrivilegeReadAppList] = useState(false);
  const [privilegeReadUserList, setPrivilegeReadUserList] = useState(false);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    let jwt = UserService.getToken();
    //let jwt = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJTQXEzRG5SSUwzekxTS1oxeVJuR0xwejJWSy1kajk5TmxSRUgwRENvZTJRIn0.eyJleHAiOjE2NjQxODc5NTQsImlhdCI6MTY2NDE4NzY1NCwiYXV0aF90aW1lIjoxNjY0MTg3NjQ5LCJqdGkiOiIyZDEzMmIxYS1hMTI0LTQwMjItYmZlZi0yOGMzYjA2YjBiZWUiLCJpc3MiOiJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2My5hbWJlcm9vbi5jb20vYXV0aC9yZWFsbXMvYXBwcyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1YjIyMTFhMy1mYWM3LTRmYjgtOWI5NC0zMGY1YjU0ODU3MzciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjb25zb2xlLXVpIiwibm9uY2UiOiJlYzhjZjcxYi03Y2ZhLTRhOWItODhjZC0wZWI5N2I3NGI2NGIiLCJzZXNzaW9uX3N0YXRlIjoiOGNkYmU3ZjUtNGEwYi00MGIyLTk4ZjctN2MzNGU4NzdmZWZhIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL21hbmF0b2tvLWN0YmMtZGV2Mi5hbWJlcm9vbi5jb20iXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWFwcHMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiOGNkYmU3ZjUtNGEwYi00MGIyLTk4ZjctN2MzNGU4NzdmZWZhIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiR2VyYXJkIEFudG9ueSIsInByZWZlcnJlZF91c2VybmFtZSI6ImdlcmFyZC5hbnRvbnlAYW1iZXJvb24uY29tIiwiZ2l2ZW5fbmFtZSI6IkdlcmFyZCIsImZhbWlseV9uYW1lIjoiQW50b255IiwiZW1haWwiOiJnZXJhcmQuYW50b255QGFtYmVyb29uLmNvbSJ9.DuVO2AX2CoZbykow6TyJBFnql6lkfKVCV_KmIQn4QXdQJCfbesfH0He-5sFqv9OcmDU6UQvMmaZlYmmbPpPb0i6HDdiEZQcHzoI7eujFjb9BJcx1PuDqGYh0YV_fLKJL2F4krVFZT2M6glxqxf39G3ChnXBKKCMxBD2UdtHx_TtQCOIFQEz3GafvU0uLNsKszWQEe72GDsa6hQEdYN8jUSkWlzdC1JBFpXfRCQaFVLSRtvtr_rWJ_HT463PlxdFpAVd6PdxvOFsUbqQH8BluTapgrR7BLzW8bUdV4bzBqTCPJmUDVap44V_VIuh8AcwQcYWCMPYu_KkTasBm0jogrA";
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('mToken'));
    console.log(privilegeReadAppList);
    setPrivilegeReadAppList(false);
    setPrivilegeReadUserList(false);
    
    if(sessionStorage.getItem('mToken') !== null && sessionStorage.getItem('mToken') !== undefined){
      /* check_user_privilege - read_user_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:sessionStorage.getItem('mToken'), privilege:'read_user_list'})
      .then(response=>{
        console.log("read_user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setPrivilegeReadUserList(false);
          } else if(response.data.is_privileged === 1){
            setPrivilegeReadUserList(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_application_list */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:sessionStorage.getItem('mToken'), privilege:'read_application_list'})
      .then(response=>{
        console.log("read_application_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setPrivilegeReadAppList(false);
          } else if(response.data.is_privileged === 1){
            setPrivilegeReadAppList(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, sessionStorage.getItem('mToken')]);

  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <Tabs defaultActiveKey="application" transition={false} className="mb-5">
            <Tab eventKey="application" title="Application">
              <ApplicationList handleLogout={handleLogout} isUserPrivileged={privilegeReadUserList} />
            </Tab>
            {privilegeReadUserList &&
              <Tab eventKey="users" title="Users">
                <Users handleLogout={handleLogout} />
              </Tab>
            }
          </Tabs>
        </Container>
      </main>
    </>
  );
}

export default Home;