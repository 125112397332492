import React, {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from "mdbreact";
import axios from "axios";

const serverConfig = require("../config/server.js");

function AppDataGrid({show, refreshAppList, token, isUserPrivileged, handleLogout}) {
  
  //const mToken = sessionStorage.getItem('mToken');
  const mToken = token;

  const [appDataGrid, setAppDataGrid] = useState([]);
  const [appDataGridRender, setAppDataGridRender] = useState([]);

  const [CPAList, setCPAList] = useState([]);
  const [TPSupportList, setTPSupportList] = useState([]);

  const handleClick = (link) => {
    console.log('clicked', link);
    window.location = link;
  }

  const setCPA = (idNum, event) => {
    console.log(event.target.value, idNum);
    axios.post(serverConfig.api_base_url + "set_cpa",{token:mToken, application_id:idNum, cpa_user_name: event.target.value})
    .then(response=>{
      console.log('set_cpa response', response);
      if(response.data.status === 200){
        console.log(response.data);
        refreshAppList();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const setTPSupport = (idNum, event) => {
    console.log(event.target.value, idNum);
    axios.post(serverConfig.api_base_url + "set_tp_support",{token:mToken, application_id:idNum, tp_support_user_name: event.target.value})
    .then(response=>{
      console.log('set_tp_support response', response);
      if(response.data.status === 200){
        console.log(response.data);
        refreshAppList();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      //get_cpa_list
      if(isUserPrivileged){
        axios.post(serverConfig.api_base_url + "get_cpa_list",{token:mToken})
        .then(response=>{
          console.log('get_cpa_list response', response);
          if(response.data.status === 200 && response.data.items !== null){
            console.log(JSON.parse(response.data.items));
            setCPAList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else if(response.data.status === 401){
            //don't show error for cpa
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }

      //get_tp_support_list
      if(isUserPrivileged){
        axios.post(serverConfig.api_base_url + "get_tp_support_list",{token:mToken})
        .then(response=>{
          console.log('get_tp_support_list response', response);
          if(response.data.status === 200 && response.data.items !== null){
            console.log(JSON.parse(response.data.items));
            setTPSupportList(JSON.parse(response.data.items));
          } else if(response.data.status === 301){
            handleLogout();
          } else if(response.data.status === 401){
            //don't show error for cpa
          } else {
            alert(response.data.message);
          }
        })
        .catch(err=>console.log("error is",err));
      }
    }
  }, [mToken, isUserPrivileged]);

  useEffect(() => {
    console.log('props', show);
    console.log(mToken, sessionStorage.getItem('mToken'));
    
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken, show);
      //get_app_list
      axios.post(serverConfig.api_base_url + "get_app_list",{filter_check:show, token:mToken})
      .then(response=>{
        console.log('get_app_list response', response);
        if(response.data.status === 200){
          let items = JSON.parse(response.data.items);
          if (items !== null || items !== undefined){
            console.log("get_app_list response items:", items);
            setAppDataGrid(items);
            setAppDataGrid((stateAppDataGrid) => {
              let appDataArray = JSON.parse(JSON.stringify(stateAppDataGrid)); // deep copy?
              let appData = [];
              console.log(appDataArray);
              //appDataArray.sort((a, b) => b.last_updated.localeCompare(a.last_updated)).map((item, index) => {
              appDataArray.reverse().map((item, index) => {
                let id_no = item.application_id;
                /* if(item.current_status.match(/^invite /)) {
                  if(item.current_status === 'invite resent'){
                    item.current_status = [<img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" height="15" className='me-2' />, 'Invite Resent']
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>)
                    item.clickEvent= () => handleClick("application-details?" + id_no);
                    item.rowClassNames= 'clickable';
                  } else {
                    if(item.current_status === 'invite sent'){
                      item.current_status = [<img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" height="15" className='me-2' />, 'Sent Invite']
                    } else if(item.current_status === 'invite expired'){
                      item.current_status = [<i key="Invite Expired" className="fas fa-exclamation-circle me-2 txt-red" aria-hidden="true"></i>, 'Invite Expired']
                    }
                  }
                } else {
                  if (item.current_status === 'under review') {
                    item.current_status = [<img src={require('./../assets/images/pending-icon.png')} alt="Pending Icon" className='me-2' />, 'Under Review']
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>)
                    item.application_id = [id_no, <span className='new'>NEW</span>];
                  } else {
                    if(item.current_status === 'approved'){
                      item.current_status = [<i key="Accepted" className="far fa-check-square me-2 txt-green" aria-hidden="true"></i>, 'Accepted']
                    } else if(item.current_status === 'denied'){
                      item.current_status = [<i key="Not Accepted" className="far fa-times-circle me-2 txt-red" aria-hidden="true"></i>, 'Not Accepted']
                    }
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>)
                  }
                  item.clickEvent= () => handleClick("application-details?" + id_no);
                  item.rowClassNames= 'clickable';
                } */
                if(item.is_completed == 0 && item.cpa_name === null){
                  item.is_completed = [<div>Unassigned</div>]
                } else if(item.is_completed == 0 && item.cpa_name !== null){
                  item.is_completed = 'Under Review'
                } else if(item.is_completed == 1){
                  item.is_completed = 'Completed';
                }
                if(item.cpa_name === null || item.tp_support_name === null){
                  /* item.cpa_name = [<select>
                    <option>Assign</option>
                    <option>Assign</option>
                  </select>]; */
                  item.actions = (<div className='d-flex align-items-center'>
                    <div>
                      {(CPAList && isUserPrivileged && item.cpa_name === null) && <div><select id="cpa-list" onChange={(e) => setCPA(id_no, e)} className='mb-1 w-100'>
                        <option value='Assign'>Assign Tax Preparer</option>
                        {CPAList.map((item, index) => <option key={index} value={item}>{item}</option>)}
                      </select></div>}
                      {(TPSupportList && isUserPrivileged && item.tp_support_name === null) && <div><select id={`tps-list-${id_no}`} onChange={(e) => setTPSupport(id_no, e)} className='mt-1 w-100'>
                        <option value='Assign'>Assign TP Support</option>
                        {TPSupportList.map((item, index) => <option key={index} value={item}>{item}</option>)}
                      </select></div>}
                    </div>
                    <Link to={"application-details?" + id_no} className='ms-2'><i className="far fa-eye txt-gray"></i></Link>
                  </div>);
                } else {
                  item.cpa_name = item.cpa_name;
                  item.actions = <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>;
                }
                item.company_name = <span dangerouslySetInnerHTML={{__html: item.company_name}} />;
                /* item.clickEvent= () => handleClick("application-details?" + id_no);
                item.rowClassNames= 'clickable'; */
                //item.last_updated = (item.last_updated).substr(0, (item.last_updated).length-7);
                appData.push(item);
              });
              console.log(appData);
              setAppDataGridRender(appData);
              return stateAppDataGrid;
            });
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [show, isUserPrivileged, mToken, CPAList, TPSupportList]);

  const data = {
    columns: [
      {
        label: 'Sl No.',
        field: 'application_id',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Application ID',
        field: 'app_id',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Agency Name',
        field: 'organization',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Company Name',
        field: 'company_name',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Contact Person',
        field: 'contact_name',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Email Id',
        field: 'email_id',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Tax Preparer',
        field: 'cpa_name',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'TP Support',
        field: 'tp_support_name',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Status',
        field: 'is_completed',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      }
    ],

    rows: appDataGridRender,
  };

  return (
    <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here"/>
  );
}

export default AppDataGrid;
