import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import Back from '../components/back';
import UserService from '../services/UserService';
import FileSaver from 'file-saver';
import ClientChat from '../components/ClientChat';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

const serverConfig = require("../config/server.js");

const ApplicationDetails = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('mToken'));

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1);
  console.log('applicationID', applicationID);

  const[userPrivilegedMessaging, setUserPrivilegedMessaging] = useState(false);
  const[userPrivilegedReadApplication, setUserPrivilegedReadApplication] = useState(false);
  const[userPrivilegedReadApplicationHistory, setUserPrivilegedReadApplicationHistory] = useState(false);
  const[userPrivilegedViewKyc, setUserPrivilegedViewKyc] = useState(false);
  const[userPrivilegedDownload, setUserPrivilegedDownload] = useState(false);
  const[userPrivilegedReadTPCommunicationLog, setUserPrivilegedReadTPCommunicationLog] = useState(false);
  const[userPrivilegedSendEngagementAgreement, setUserPrivilegedSendEngagementAgreement] = useState(false);
  const[userPrivilegedOnboardingApprove, setUserPrivilegedOnboardingApprove] = useState(false);
  const[userPrivilegedReadPayroll, setUserPrivilegedReadPayroll] = useState(false);
  const[userPrivilegedReadPseudonymizedPayroll, setUserPrivilegedReadPseudonymizedPayroll] = useState(false);
  const[userPrivilegedWritePseudonymizedPayroll, setUserPrivilegedWritePseudonymizedPayroll] = useState(false);
  const[userPrivilegedReadProcessedPayroll, setUserPrivilegedReadProcessedPayroll] = useState(false);
  const[userPrivilegedWriteProcessedPayroll, setUserPrivilegedWriteProcessedPayroll] = useState(false);
  const[userPrivilegedReadSupportPayroll, setUserPrivilegedReadSupportPayroll] = useState(false);
  const[userPrivilegedWriteSupportPayroll, setUserPrivilegedWriteSupportPayroll] = useState(false);
  const[userPrivilegedUpdateDocument, setUserPrivilegedUpdateDocument] = useState(false);
  const[userPrivilegedPIIInfo, setUserPrivilegedPIIInfo] = useState(false);

  const[file, setFile] = useState('');
  const[excelFile, setExcelFile] = useState(null);
  const[DLBase64, setDLBase64] = useState(null);

  const [appData, setAppData] = useState('');
  const [appDataTimestamp, setAppDataTimestamp] = useState('');
  const [appDataStatus, setAppDataStatus] = useState(null);
  const [dataName, setDataName] = useState('');
  const [dataEmailid, setDataEmailid] = useState('');
  const [dataPhoneNum, setDataPhoneNum] = useState('');
  const [dataCovidImpactedQuarters, setDataCovidImpactedQuarters] = useState([]);
  const [dataReducedServiceHoursReasons, setDataReducedServiceHoursReasons] = useState([]);
  const [dataClaimedCredits, setDataClaimedCredits] = useState([]);
  const [disruptionData, setDisruptionData] = useState([]);
  const [locationDisruptionData, setLocationDisruptionData] = useState([]);
  const [staffingDisruptionData, setStaffingDisruptionData] = useState({});
  const [SoFSignedByCustomer, setSoFSignedByCustomer] = useState([]);
  const [engagementSignatureStatus, setEngagementSignatureStatus] = useState([]);

  const[tpCommunicationLog, setTPCommunicationLog] = useState([]);
  const [auditData, setAuditData] = useState([]);

  const [denyMarkCompleted, setDenyMarkCompleted] = useState(false);

  //const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showFileModal, setShowFileModal] = useState(false);

  const [showReportModal, setShowReportModal] = useState(false);
  const [reportData, setReportData] = useState("");

  const [sofInputs, setSofInputs] = useState({});
  const [sofOption, setSofOption] = useState("");

  const[timeframeStartDate,setTimeframeStartDate] = useState(null);
  const[timeframeEndDate,setTimeframeEndDate] = useState(null);

  const getAppData = () => {
    axios.post(serverConfig.api_base_url + "get_app_data",{token:mToken, app_id:applicationID})
    .then(response=>{
      setIsLoading(false);
      console.log(mToken);
      console.log("get_app_data response:", response);
      console.log(response.data.status);
      if(response.data.status === 200 ){
        if(response.data.output_data !== null){
          let data = JSON.parse(response.data.output_data);
          console.log("get_app_data response:", data);
          setDataName(data.name);
          setDataEmailid(data.email_id);
          setDataPhoneNum(data.phone_number);
          setAppData(JSON.parse(JSON.stringify(data.app_data).replace(/&apos;/g,"'")));
          data.app_data.CovidImpactedQuarters && setDataCovidImpactedQuarters(JSON.parse(data.app_data.CovidImpactedQuarters));
          data.app_data.ReducedServiceHoursReasons && setDataReducedServiceHoursReasons(JSON.parse(data.app_data.ReducedServiceHoursReasons));
          data.app_data.ClaimedCredits && setDataClaimedCredits(JSON.parse(data.app_data.ClaimedCredits));
          //console.log(data.app_data.StatewiseDisruptionData && JSON.parse(data.app_data.StatewiseDisruptionData));
          data.app_data.StatewiseDisruptionData && setDisruptionData(JSON.parse(data.app_data.StatewiseDisruptionData));
          data.app_data.LocationwiseDisruptionData && setLocationDisruptionData(JSON.parse(data.app_data.LocationwiseDisruptionData));
          data.app_data.StaffingDisruptionData && setStaffingDisruptionData(JSON.parse(data.app_data.StaffingDisruptionData));
          data.app_data.StatementOfFactSignedByCustomer && setSoFSignedByCustomer(JSON.parse(data.app_data.StatementOfFactSignedByCustomer));
          console.log(data.app_data.StatementOfFactFile);
          console.log(data.app_data.CdCompanyName);
          if(data.app_data.StatementOfFactFile){
            setSofOption('sof-upload');
          } else if(data.app_data.CdCompanyName){
            setSofOption('sof-template');
          }
          //console.log(data.app_data.StatementOfFactSignedByCustomer && JSON.parse(data.app_data.StatementOfFactSignedByCustomer));
          data.app_data.EngagementSignatureStatus && setEngagementSignatureStatus(JSON.parse(data.app_data.EngagementSignatureStatus));
          setAppDataTimestamp(data.app_data_timestamp);
          setAppDataStatus(data.app_data_status);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }
  
  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    //let emailid = UserService.getUsername();
    //console.log('email', emailid);
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "get_user_token",{jwt:jwt})
    .then(response=>{
      console.log('get_user_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      //DriversLicense base64
      axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:'DriversLicense', application_id:applicationID})
      .then(response=>{
        if (response.data.status === 200) {
          setDLBase64(response.data.data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - update_application */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'update_application'})
      .then(response=>{
        console.log("check_user_privilege update_application response:", response);
        if(response.data.status === 200){
          console.log("check_user_privilege update_application response:", response.data.is_privileged);
          let denyMarkCompleted = response.data.is_privileged !== 0;
          setDenyMarkCompleted(denyMarkCompleted);
          console.log("denyMarkCompleted=" + denyMarkCompleted);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      
      /* check_user_privilege - read_application */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_application'})
      .then(response=>{
        console.log("read_application privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadApplication(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadApplication(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_application_history */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_application_history'})
      .then(response=>{
        console.log("read_application_history privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadApplicationHistory(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadApplicationHistory(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - messaging */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'messaging'})
      .then(response=>{
        console.log("messaging privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedMessaging(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedMessaging(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - view_tp_communication_log */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'view_tp_communication_log'})
      .then(response=>{
        console.log("view_tp_communication_log privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadTPCommunicationLog(true);
            
            axios.post(serverConfig.api_base_url + "get_message_list",{token:mToken, application_id:applicationID})
            .then(response=>{
              console.log('get_message_list response', response);
              if(response.data.status === 200){
                if(response.data.items !== null){
                  let msgArray = JSON.parse(response.data.items);
                  console.log('get_message_list success', msgArray);
                  setTPCommunicationLog(msgArray);
                }
              }
            })
            .catch(err=>console.log("error is",err));
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadTPCommunicationLog(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - view_kyc_details */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'view_kyc_details'})
      .then(response=>{
        console.log("view_kyc_details privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedViewKyc(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedViewKyc(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - send_engagement_agreement */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'send_engagement_agreement'})
      .then(response=>{
        console.log("send_engagement_agreement privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedSendEngagementAgreement(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedSendEngagementAgreement(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - onboarding_approve */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'onboarding_approve'})
      .then(response=>{
        console.log("onboarding_approve privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedOnboardingApprove(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedOnboardingApprove(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - download_all */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'download_all'})
      .then(response=>{
        console.log("download_all privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedDownload(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedDownload(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_payroll_data'})
      .then(response=>{
        console.log("read_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_pseudonymized_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_pseudonymized_payroll_data'})
      .then(response=>{
        console.log("read_pseudonymized_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadPseudonymizedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadPseudonymizedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_pseudonymized_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_pseudonymized_payroll_data'})
      .then(response=>{
        console.log("write_pseudonymized_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWritePseudonymizedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWritePseudonymizedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_processed_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_processed_payroll_data'})
      .then(response=>{
        console.log("read_processed_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadProcessedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadProcessedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_processed_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_processed_payroll_data'})
      .then(response=>{
        console.log("write_processed_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWriteProcessedPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWriteProcessedPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_support_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_support_payroll_data'})
      .then(response=>{
        console.log("read_support_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedReadSupportPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedReadSupportPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - write_support_payroll_data */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'write_support_payroll_data'})
      .then(response=>{
        console.log("write_support_payroll_data privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedWriteSupportPayroll(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedWriteSupportPayroll(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - update_document */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'update_document'})
      .then(response=>{
        console.log("update_document privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedUpdateDocument(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedUpdateDocument(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* check_user_privilege - read_pii_info */
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token:mToken, privilege:'read_pii_info'})
      .then(response=>{
        console.log("read_pii_info privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 1){
            setUserPrivilegedPIIInfo(true);
          } else if(response.data.is_privileged === 0){
            setUserPrivilegedPIIInfo(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        } 
      })
      .catch(err=>console.log("error is",err));

      /* get_app_data */
      console.log('mToken', mToken);
      getAppData();
      console.log(appData);

      axios.post(serverConfig.api_base_url + "get_app_history",{token:mToken, app_id:applicationID})
      .then(response=>{
        console.log('get_app_history response', response);
        if (response.data.status === 200 && response.data.items !== null) {
          let data = JSON.parse(response.data.items);
          console.log(data);
          setAuditData(data);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  const showFile = (entityname) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    console.log('get file');
    axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:entityname, application_id:applicationID})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        document.body.classList.remove('loader-show');
        console.log('loaded');

        if((response.data.data).startsWith("data:application/vnd") || (response.data.data).startsWith("data:text/csv")){
          console.log(response.data.data);

          var arr = response.data.data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
          var file = new File([u8arr], appData[entityname], {type:mime});
          console.log(file);

          ExcelRenderer(file, (err, resp) => {
            const columns = [{name: '', key: 0}];
            resp.cols.map(item => {
                columns.push({name: item.name, key: item.key + 1});
                return null;
            })
            if(err){
              console.log(err);            
            }
            else{
              //let newCol = [{name: '', key: ''},...resp.cols];
              console.log(resp);
              //console.log(newCol);
              setExcelFile({rows:resp.rows, cols:columns});
              setShowFileModal(true);
            }
          }); 
          //alert('The File will now be downloaded into the folder that saves all your "Downloads".');
          //FileSaver.saveAs(response.data.data, appData[entityname]);
          
        } else {
          console.log(response.data.data);
          setFile(response.data.data);
          setShowFileModal(true);
        }
        
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const downloadFile = (entityname) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    axios.post(serverConfig.api_base_url + "get_file",{token:mToken, entity:entityname, application_id:applicationID})
    .then(response=>{
      if (response.data.status === 200) {
        setIsLoading(false);
        document.body.classList.remove('loader-show');
        console.log('loaded');

        alert('The File will now be downloaded into the folder that saves all your "Downloads".');
        FileSaver.saveAs(response.data.data, appData[entityname]);
        
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const showReport = (entityname) => {
    console.log(appData[entityname]);
    var obj = JSON.parse(appData[entityname]);
    setReportData(<pre>{JSON.stringify(obj, null, 4)}</pre>);
    setShowReportModal(true);
  }

  const getIDMSubfields = (subreport) => {
    return Object.keys(subreport).map(key =>
      //"<tr><th>"+key+"</th><td>"+subreport[key]["@PSGFDisplayValue"]+"</td></tr>");
      <tr><th>{key}</th><td>{subreport[key]["@PSGFDisplayValue"]}</td></tr>);
  }

  const getIDMissionFields = (reportData) => {
    var html = <table><tbody> {getIDMSubfields(reportData.ID_Image_Processing)} {getIDMSubfields(reportData.Additional_Data)} </tbody></table>;
    return html;
  }

  const showDLReport = (entityname) => {
    console.log(appData[entityname]);
    var obj = JSON.parse(appData[entityname]);
    console.log(obj.FormDetails);
    setReportData(getIDMissionFields(obj.FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing));
    setShowReportModal(true);
  }

  const markAsCompleted = () => {
    axios.post(serverConfig.api_base_url + "set_completed",{token:mToken, app_id:applicationID})
    .then(response=>{
      console.log('set_completed response', response);
      if (response.data.status === 200) {
        navigate('/');
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    } )
    .catch(err=>console.log("error is",err));
  }

  const downloadAll = () => {
    console.log('calling download all');
    setIsLoading(true);
    axios.post(serverConfig.api_base_url + "get_files_zip",{token:mToken, application_id:applicationID}, {
      responseType: 'arraybuffer'
    })
    .then(response=>{
      console.log('get_files_zip response', response);
      var blob = new Blob([response.data], {type: "octet/stream"});
      console.log(blob.size);
      alert('Files will now be downloaded into the folder that saves all your "Downloads".');
      FileSaver.saveAs(blob, appData.EINNumber+'.zip');
      setIsLoading(false);
    } )
    .catch(err=>console.log("error is",err));
  }

  const [showSoF, setShowSoF] = useState(false);
  const handleCloseSoF = () => {
    setSofInputs({});
    setShowSoF(false);
  }
  const handleShowSoF = () => {
    setSofInputs({
      CdCompanyName: appData.CdCompanyName,
      CdFEINNumber: appData.CdFEINNumber,
      CdIndustry: appData.CdIndustry,
      CdLocation: appData.CdLocation,
      CdAvgEmp2019: appData.CdAvgEmp2019,
      CdPPP1Period: appData.CdPPP1Period,
      CdPPP2Period: appData.CdPPP2Period,
      CdOwnersNameAndPercentageOwnership: appData.CdOwnersNameAndPercentageOwnership,
      CdOwnersRelatedAndRelationship: appData.CdOwnersRelatedAndRelationship,
      CdEmployeeNamesRelatedOwnerRelationship: appData.CdEmployeeNamesRelatedOwnerRelationship,
      CdGrossReceipt2019Q1: appData.CdGrossReceipt2019Q1,
      CdGrossReceipt2019Q2: appData.CdGrossReceipt2019Q2,
      CdGrossReceipt2019Q3: appData.CdGrossReceipt2019Q3,
      CdGrossReceipt2019Q4: appData.CdGrossReceipt2019Q4,
      CdGrossReceipt2020Q1: appData.CdGrossReceipt2020Q1,
      CdGrossReceipt2020Q2: appData.CdGrossReceipt2020Q2,
      CdGrossReceipt2020Q3: appData.CdGrossReceipt2020Q3,
      CdGrossReceipt2020Q4: appData.CdGrossReceipt2020Q4,
      CdGrossReceipt2021Q1: appData.CdGrossReceipt2021Q1,
      CdGrossReceipt2021Q2: appData.CdGrossReceipt2021Q2,
      CdGrossReceipt2021Q3: appData.CdGrossReceipt2021Q3,
      CdGrossReceipt2021Q4: appData.CdGrossReceipt2021Q4,
      TpEligibilityTimeframe: appData.TpEligibilityTimeframe,
      TpEligibilityNotesGR: appData.TpEligibilityNotesGR && appData.TpEligibilityNotesGR.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpEligibilityNotesSuspension: appData.TpEligibilityNotesSuspension && appData.TpEligibilityNotesSuspension.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpEmployerProvidedFacts: appData.TpEmployerProvidedFacts && appData.TpEmployerProvidedFacts.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'"),
      TpAdditionalComments: appData.TpAdditionalComments && appData.TpAdditionalComments.replace(/<br\s*\/?>/gi,'\n').replace(/&apos;/g,"'")
    });
    setShowSoF(true);
  }

  const handleUpdateCustomerDetails = () => {
    console.log(sofInputs);
    console.log(appData.TpEmployerProvidedFacts);
    console.log(sofInputs.TpEmployerProvidedFacts);
    let location = '';
    if(appData.PermanentAddressLine1){
      location += appData.PermanentAddressLine1 +', ';
      if(appData.PermanentAddressLine2){
        location += appData.PermanentAddressLine2 +', ';
      }
      location += appData.PermanentState +', ';
      location += appData.PermanentCity +', ';
      location += appData.PermanentPostalCode;
    }
    let avgEmp2019 = Number(appData.FTE2019) + Number(appData.PTE2019);

    let ppp1period, ppp2period;
    if(appData.PPP1StartDate && appData.PPP1EndDate){
      ppp1period = appData.PPP1StartDate +' to '+ appData.PPP1EndDate;
    }
    if(appData.PPP2StartDate && appData.PPP2EndDate){
      ppp2period = appData.PPP2StartDate +' to '+ appData.PPP2EndDate;
    }

    setSofInputs({
      ...sofInputs,
      CdCompanyName: appData.CompanyName && appData.CompanyName.replace(/'/g, '&apos;'),
      CdFEINNumber: appData.EINNumber && appData.EINNumber.replace(/'/g, '&apos;'),
      CdIndustry: appData.Industry,
      CdLocation: location && location.replace(/'/g, '&apos;'),
      CdAvgEmp2019: avgEmp2019,
      CdPPP1Period: ppp1period,
      CdPPP2Period: ppp2period,
      CdOwnersNameAndPercentageOwnership: appData.OwnersNameAndPercentageOwnership && appData.OwnersNameAndPercentageOwnership.replace(/'/g, '&apos;'),
      CdOwnersRelatedAndRelationship: appData.OwnersRelatedAndRelationship && appData.OwnersRelatedAndRelationship.replace(/'/g, '&apos;'),
      CdEmployeeNamesRelatedOwnerRelationship: appData.EmployeeNamesRelatedOwnerRelationship && appData.EmployeeNamesRelatedOwnerRelationship.replace(/'/g, '&apos;'),
      CdGrossReceipt2019Q1: appData.GrossReceipt2019Q1,
      CdGrossReceipt2019Q2: appData.GrossReceipt2019Q2,
      CdGrossReceipt2019Q3: appData.GrossReceipt2019Q3,
      CdGrossReceipt2019Q4: appData.GrossReceipt2019Q4,
      CdGrossReceipt2020Q1: appData.GrossReceipt2020Q1,
      CdGrossReceipt2020Q2: appData.GrossReceipt2020Q2,
      CdGrossReceipt2020Q3: appData.GrossReceipt2020Q3,
      CdGrossReceipt2020Q4: appData.GrossReceipt2020Q4,
      CdGrossReceipt2021Q1: appData.GrossReceipt2021Q1,
      CdGrossReceipt2021Q2: appData.GrossReceipt2021Q2,
      CdGrossReceipt2021Q3: appData.GrossReceipt2021Q3,
      CdGrossReceipt2021Q4: appData.GrossReceipt2021Q4,
      TpEligibilityNotesGR: appData.TpEligibilityNotesGR,
      TpEligibilityNotesSuspension: appData.TpEligibilityNotesSuspension,
      TpEmployerProvidedFacts: appData.TpEmployerProvidedFacts,
      TpAdditionalComments: appData.TpAdditionalComments
    });
  }

  const handleChange = (event) => {
    //console.log('changing', event, event.target.value);
    console.log(event.target.type);
    const name = event.target.name;
    let value = event.target.value;

    const newData={...sofInputs};

    if(event.target.type === 'textarea'){
      if(value !== ''){
        value = value.replace(/(\r\n|\r|\n)/g, '<br />').replace(/'/g, '&apos;');
      }
    }

    if(name === 'TimeframeStartDate'){
      setTimeframeStartDate(value);
      newData['TpEligibilityTimeframe'] = value +' to '+ timeframeEndDate;
    } else if(name === 'TimeframeEndDate'){
      setTimeframeEndDate(value);
      newData['TpEligibilityTimeframe'] = timeframeStartDate +' to '+ value;
    } else {
      newData[name]=value;
    }

    console.log(newData);
    setSofInputs(newData);
    console.log('sofInputs', sofInputs);
  }

  const handleSaveSoFData = (e) => {
    e.preventDefault();
    console.log(sofInputs);
    var formdata = {token:mToken, application_id: applicationID, entity_group:'statement_of_fact', items:sofInputs};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        getAppData();//refresh statement of fact status
        handleCloseSoF();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }


  const handleUpload = (groupName, event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name,value);
    var extension = value.split('.').pop();
    console.log(extension);

    let uploadFunc = () => {
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      console.log(file);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          console.log({token:mToken, application_id: applicationID, entity_group:groupName, entity:name, file_name: filename, file_data: fileData});
          axios.post(serverConfig.api_base_url + "set_file",{token:mToken, application_id: applicationID, entity_group:groupName, entity:name, file_name: filename, file_data: fileData})
          .then(response=>{
            console.log('set_file response', response);
            if(response.data.status === 200) {
              setIsLoading(false);
              console.log('success');
              getAppData();//update to new filename
            } else if(response.data.status === 301){
              handleLogout();
            } else {
              event.target.value = '';
              alert(response.data.message);
              setIsLoading(false);
            }
          })
          .catch(err=>console.log("error is",err));
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    }

    if(groupName === 'final_form_941x' || groupName === 'pseudonymized_payroll' || groupName === 'processed_payroll' || groupName === 'documents_from_tp_support'){
      if(extension=='xls' || extension=='xlsx' || extension=='csv' || extension=='pdf' || extension=='docx'){
        uploadFunc();
      } else {
        alert("Only xls, xlsx, csv, pdf and docx files are allowed");
        event.target.value = null;
      }
    } else if(groupName === 'engagement_agreement'){
      if(extension=='pdf'){
        uploadFunc();
      } else {
        alert("Only pdf file is allowed");
        event.target.value = null;
      }
    } else if(groupName === 'statement_of_fact'){
      if(extension=='pdf'){
        uploadFunc();
      } else {
        alert("Only pdf file is allowed");
        event.target.value = null;
      }
    }
  }

  const handleSetData = (group_name, entity_name, status, e) => {
    e.preventDefault();
    var formdata = {token:mToken, application_id: applicationID, entity_group:group_name, items:{[entity_name]: status}};
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "set_data",formdata)
    .then(response=>{
      console.log('set_data response', response);
      if(response.data.status === 200) {
        getAppData();//refresh status
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleSetEngagementAgreementTemplateName = (e) => {
    console.log(e.target.value);
    if(e.target.value !== ''){
      var formdata = {token:mToken, application_id: applicationID, entity_group:'engagement_agreement', items:{EngagementAgreementTemplateName: e.target.value}};
      console.log(formdata);
      axios.post(serverConfig.api_base_url + "set_data",formdata)
      .then(response=>{
        console.log('set_data response', response);
        if(response.data.status === 200) {
          getAppData();//refresh statement of fact status
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handleSelectSofOption = (e) => {
    console.log(e.target.value);
    if(e.target.value === 'sof-upload'){
      setSofOption('sof-upload');
    } else if(e.target.value === 'sof-template'){
      setSofOption('sof-template');
    }
  }
  
  return (
    <>
      <Header />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        {userPrivilegedReadApplication && <>
          <section>
            <Container fluid>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='page-title'>
                  <Back />
                  <div>
                    <div>Applicaion ID: <b>{appData.ApplicationId}</b></div>
                    <h1 className='mb-0'>{appData.CompanyName}</h1>
                  </div>
                </div>
                {appData.Submitted ? <>&nbsp;</> : <div className='txt-red'><b>User submission pending!</b></div>}
                {/* {appDataStatus && <div className='txt-red'><b>{appDataStatus}</b></div>} */}
              </div>
            </Container>
          </section>
          {/* User Information */}
          <section className='bg-gray'>
            <Container fluid>
            <h6>User Information</h6>
              <Row className='mb-3'>
                <Col><span className='label'>Full Name:</span><br/><span>{appData.UserFirstName} {appData.UserMiddleName} {appData.UserLastName}</span></Col>
                <Col><span className='label'>Title:</span><br/><span>{appData.UserTitle}</span></Col>
                <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/><span>{appData.UserPhoneNumber}</span></>}</Col>
                <Col>{userPrivilegedPIIInfo && <><span className='label'>Email address:</span><br/><span>{appData.UserEmail}</span></>}</Col>
              </Row>
              <Row>
                <Col><span className='label'>ID Verification:</span><br/><span>{appData.DriversLicense ? 'Completed' : 'Pending'}</span></Col>
                <Col><span className='label'>Is business owner:</span><br/><span>{appData.IsBusinessOwner}</span></Col>
                <Col xs={6}><span className='label'>Is authorized signer for the business:</span><br/><span>{appData.IsAuthSigner}</span></Col>
              </Row>
            </Container>
          </section>
          {/* Company Information */}
          <section className='bg-gray'>
            <Container fluid>
              <h6>Company Information</h6>
              <section>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Business Details</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    <Col md="3"><span className='label'>Company Description:</span> {appData.CompanyDescription}</Col>
                    <Col><span className='label'>NAICS Code:</span> {appData.NaicsCode}</Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col><span className='label'>EIN Number:</span><br/>{appData.EINNumber}</Col>
                    <Col><span className='label'>Trade name or DBA:</span><br/>{appData.TradeNameDBA}</Col>
                    <Col><span className='label'>Website URL:</span><br/>{appData.WebsiteURL}</Col>
                    <Col><span className='label'>Industry:</span><br/>{appData.Industry}</Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col><span className='label'>Approximate annual company revenue:</span><br/>{appData.AnnualCompanyRevenue}</Col>
                    <Col><span className='label'>Payroll done by a PEO:</span><br/>{appData.PayrollByPEO}</Col>
                    <Col><span className='label'>Entity filling type:</span><br/>{appData.EntityFillingType}</Col>
                    <Col><span className='label'> Business started after 15th Feb, 2020:</span><br/>{appData.IsNewBiz}</Col>
                  </Row>
                  <section className='bdr-top'>
                    <Row>
                      <Col><span className='label'>Permanent Address</span><br/>
                        {appData.PermanentAddressLine1}, {appData.PermanentAddressLine2 && <>{appData.PermanentAddressLine2}, </>}
                        {appData.PermanentState}, {appData.PermanentCity}, {appData.PermanentPostalCode}
                      </Col>
                      <Col>
                        {appData.MailingAddressLine1 && <>
                          <span className='label'>Mailing Address</span><br/>
                          {appData.MailingAddressLine1}, {appData.MailingAddressLine2 && <>{appData.MailingAddressLine2}, </>}
                          {appData.MailingState}, {appData.MailingCity}, {appData.MailingPostalCode}
                        </>}
                      </Col>
                    </Row>
                  </section>
                </div>
              </section>
              <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Other Details</b></div>
                <div className="sub-group">
                  <ul className='ps-4'>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Already applied for ERC for 2020/2021:</span></Col>
                        <Col>{appData.AlreadyAppliedForERC}{appData.AlreadyAppliedForERCExplaination && <>. <span dangerouslySetInnerHTML={{__html: appData.AlreadyAppliedForERCExplaination}}/></>}</Col>
                      </Row>
                    </li>
                    {/* <li>
                      <Row>
                        <Col xs={8}><span className='label'>Company qualified for and claimed any federal government incentives or tax credits within the past three years:</span></Col>
                        <Col>{appData.TakenGovCredits}</Col>
                      </Row>
                    </li> */}
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Company previously claimed following incentive or credit:</span></Col>
                        <Col>
                          {dataClaimedCredits && dataClaimedCredits.map((items,index) => <span key={index}>{items}{index+1 !== dataClaimedCredits.length && <>,<br/></>}</span>)}
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Company accepted funds from either a PPP or PPP2:</span></Col>
                        <Col>{appData.TakenPPP}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Is your business a sole proprietorship:</span></Col>
                        <Col>{appData.IsSoleProp}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Is your company a government, quasi-government, public sector entity or a taxing authority:</span></Col>
                        <Col>{appData.InRestrictedIndustry}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company have business connections in any of the following embargoed regions/countries: Crimea, Cuba, Iran, North Korea, Russia, Syria or either the Luhansk or Donetsk regions of Ukraine:</span></Col>
                        <Col>{appData.InRestrictedRegion}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company have a business bank account with a U.S. registered bank (including foreign banks with registered U.S. branches):</span></Col>
                        <Col>{appData.HaveUSBank}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does a private equity fund, venture capital fund or hedge fund have a direct or indirect ownership stake or investment in your company:</span></Col>
                        <Col>{appData.IsOwnedbyFund}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does another company own at least 50% by vote or value of your company:</span></Col>
                        <Col>{appData.IsOwnedbyOtherBiz}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company own at least 50% by vote or value of one or more other companies:</span></Col>
                        <Col>{appData.IsOwnsOtherBiz}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does the same individual or group of five or fewer individuals, estates or trusts own at least 80% of your company AND one or more other companies; and is the individual or group's common ownership of one or more of those companies at least 50%:</span></Col>
                        <Col>{appData.IsAffiliatedEntity}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col xs={8}><span className='label'>Does your company treat itself as part of an affiliated service group for purposes of employee benefit plans:</span></Col>
                        <Col>{appData.HasAffiliatedBenefits}</Col>
                      </Row>
                    </li>
                  </ul>
                  <section className='bdr-top mt-3'><span className='label'>List of Legal Company Name and EIN Number of all other affiliated companies:</span><div className='border p-2 bg-white'>{appData.RelatedEntities ? <span dangerouslySetInnerHTML={{__html: appData.RelatedEntities}}/> : <>&nbsp;</>}</div></section>
                </div>
              </section>

              {appData.Industry === 'Banks' ? <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Bank Specific Details</b></div>
                <div className="sub-group">
                  <div>
                    <div><b>Ownership</b></div>
                    <span className='label'>Names of owner(s) & percentage(s) of ownership:</span><div className='border p-2 mb-3 bg-white'>{appData.OwnersNameAndPercentageOwnershipBanks ? <span dangerouslySetInnerHTML={{__html: appData.OwnersNameAndPercentageOwnershipBanks}}/> : <>&nbsp;</>}</div>
                    <span className='label'>Name of all employee(s) who are related to any owner and name of the related owner(s). Please mention the relationship between such employee(s) and owner(s):</span><div className='border p-2 bg-white'>{appData.OwnersRelatedAndRelationshipBanks ? <span dangerouslySetInnerHTML={{__html: appData.OwnersRelatedAndRelationshipBanks}}/> : <>&nbsp;</>}</div>
                  </div>
                  <section className='bdr-top mt-3'>
                    <div><b>CFO</b></div>
                    <Row>
                      <Col><span className='label'>Name:</span><br/>{appData.CfoFullName}</Col>
                      <Col><span className='label'>Address:</span><br/>{appData.CfoAddress}</Col>
                      <Col>{userPrivilegedPIIInfo && <><span className='label'>Email:</span><br/>{appData.CfoEmail}</>}</Col>
                      <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/>{appData.CfoPhoneNumber}</>}</Col>
                    </Row>
                  </section>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.CfoAddressLine1onFileWithIRS && <>{appData.CfoAddressLine1onFileWithIRS}, </>} {appData.CfoAddressLine2onFileWithIRS && <>{appData.CfoAddressLine2onFileWithIRS}, </>}{appData.CfoState && <>{appData.CfoState}, </>}{appData.CfoCity && <>{appData.CfoCity}, </>}{appData.CfoZip && <>{appData.CfoZip}</>}
                      </Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <div><b>Identification</b></div>
                    <Row>
                      <Col><span className='label'>RSSD ID:</span><br/>{appData.IdrssdId}</Col>
                      <Col><span className='label'>DUNS:</span><br/>{appData.Duns}</Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <div><b>Gross Receipts Decline</b></div>
                    <Row>
                      <Col><span className='label'>Did the business experience a significant decline in gross receipts (50%) in any quarter during 2020, as compared to the same quarter in 2019?:</span><br/>{appData.GRDeclineAnyQuarter}</Col>
                      <Col><span className='label'>Did the business experience a significant decline in gross receipts (20%) in Q4 of 2020 or any quarter during 2021, as compared to the same quarter in 2019?:</span><br/>{appData.GRDeclineQ4}</Col>
                    </Row>
                  </section>
                </div>
              </section> : <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Business Owner</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    <Col><span className='label'>Name:</span><br/>{appData.BusinessOwnerFullName}</Col>
                    <Col><span className='label'>Address:</span><br/>{appData.BusinessOwnerAddress}</Col>
                    <Col><span className='label'>Email:</span><br/>{appData.BusinessOwnerEmail}</Col>
                    <Col><span className='label'>Phone Number:</span><br/>{appData.BusinessOwnerPhoneNumber}</Col>
                  </Row>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.BusinessOwnerAddressLine1onFileWithIRS && <>{appData.BusinessOwnerAddressLine1onFileWithIRS}, </>} {appData.BusinessOwnerAddressLine2onFileWithIRS && <>{appData.BusinessOwnerAddressLine2onFileWithIRS}, </>}{appData.BusinessOwnerState && <>{appData.BusinessOwnerState}, </>}{appData.BusinessOwnerCity && <>{appData.BusinessOwnerCity}, </>}{appData.BusinessOwnerZip && <>{appData.BusinessOwnerZip}</>}
                      </Col>
                    </Row>
                  </section>
                  <section className='bdr-top mt-3'>
                    <span className='label'>Names of owner(s) & percentage(s) of ownership:</span><div className='border p-2 mb-3 bg-white'>{appData.OwnersNameAndPercentageOwnership ? <span dangerouslySetInnerHTML={{__html: appData.OwnersNameAndPercentageOwnership}}/> : <>&nbsp;</>}</div>
                    <span className='label'>Name of all employee(s) who are related to any owner and name of the related owner(s). Please mention the relationship between such employee(s) and owner(s):</span><div className='border p-2 bg-white'>{appData.OwnersRelatedAndRelationship ? <span dangerouslySetInnerHTML={{__html: appData.OwnersRelatedAndRelationship}}/> : <>&nbsp;</>}</div>
                  </section>
                </div>
              </section>}
              
              {appData.Industry === 'Banks' ? <>&nbsp;</> : <>
                {userPrivilegedViewKyc &&
                  <section className='bdr-top'>
                    <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Verification report</b></div>
                    <div className="sub-group">
                      <Row className='mb-3'>
                        <Col>
                          {appData.DriversLicense && <><button className='verification' onClick={() => showReport('DriversLicense')}><span>ID Verification</span><img src={require('./../assets/images/id-verification-icon.png')} alt="ID icon" /></button>
                          <Modal className='modal-medium show-report' show={showReportModal} onHide={() => setShowReportModal(false)} backdrop="static" keyboard={false} centered>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div>{reportData}</div>
                            </Modal.Body>
                          </Modal>
                          </>}
                        </Col>
                        <Col>{appData.OpenCorporateDataOfficer && <button className='verification' onClick={() => showReport('OpenCorporateDataOfficer')}><span>Business Owner Verification</span><img src={require('./../assets/images/user-icon-2.png')} alt="Owner icon" /></button>}</Col>
                        <Col>{appData.OpenCorporateDataCompany && <button className='verification' onClick={() => showReport('OpenCorporateDataCompany')}><span>Business  Verification</span><img src={require('./../assets/images/business-icon.png')} alt="Business icon" /></button>}</Col>
                      </Row>
                    </div>
                  </section>
                }
              </>}
              <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Authorized Signer</b></div>
                <div className="sub-group">
                  <Row className='mb-3'>
                    <Col><span className='label'>Name:</span><br/>{appData.AuthorizedSignerFullName}</Col>
                    <Col><span className='label'>Address:</span><br/>{appData.AuthorizedSignerAddress}</Col>
                    <Col>{userPrivilegedPIIInfo && <><span className='label'>Email:</span><br/>{appData.AuthorizedSignerEmail}</>}</Col>
                    <Col>{userPrivilegedPIIInfo && <><span className='label'>Phone Number:</span><br/>{appData.AuthorizedSignerPhoneNumber}</>}</Col>
                  </Row>
                  <section className='bdr-top'>
                    <Row>
                      <Col>
                        <span className='label'>Address on File with IRS</span><br/>
                        {appData.AddressLine1onFileWithIRS && <>{appData.AddressLine1onFileWithIRS}, </>} {appData.AddressLine2onFileWithIRS && <>{appData.AddressLine2onFileWithIRS}, </>}{appData.AuthorizedSignerState && <>{appData.AuthorizedSignerState}, </>}{appData.AuthorizedSignerCity && <>{appData.AuthorizedSignerCity}, </>}{appData.AuthorizedSignerZip && <>{appData.AuthorizedSignerZip}</>}
                      </Col>
                    </Row>
                  </section>
                </div>
              </section>
              <section className='bdr-top'>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Employee Details</b></div>
                <div className="sub-group">
                  <Row>
                    <Col className='bdr-right'>
                      <ul className='ps-4'>
                        {/* <li>
                          <Row>
                            <Col xs={8}><span className='label'>Company or its partners has 80% or more ownership in any other businesses:</span></Col>
                            <Col>{appData.MoreThan80PerOwnership}</Col>
                          </Row>
                        </li> */}
                        <li>
                          <Row>
                            <Col xs={8}><span className='label'>Hourly worker records maintained and available for 2019 / 2020 / 2021:</span></Col>
                            <Col>{appData.MaintainHourlyWorkerRecords}</Col>
                          </Row>
                        </li>
                        <li>
                          <Row>
                            <Col xs={8}><span className='label'>Healthcare provided for employees:</span></Col>
                            <Col>{appData.ProvideHealthcare}</Col>
                          </Row>
                        </li>
                        <li>
                          <Row>
                            <Col xs={8}><span className='label'>Does any of the employees own more than 50% of the business:</span></Col>
                            <Col>{appData.EmployeesOwnMoreThan50PerBusiness}</Col>
                          </Row>
                        </li>
                        <li>
                          <Row>
                            <Col xs={8}><span className='label'>Were you required to file 1094C for the year 2019:</span></Col>
                            <Col>
                              {appData.RequiredToFile1094C}
                              {appData.File1094CDoc && <div>
                                {appData.File1094CDoc}<br/>
                                <button type="button" className='view-file' title={appData.File1094CDoc} onClick={() => showFile('File1094CDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.File1094CDoc} onClick={() => downloadFile('File1094CDoc')}><i className="fas fa-file-download"></i></button>
                              </div>}
                            </Col>
                          </Row>
                        </li>
                      </ul>
                    </Col>
                    <Col>
                      <b>Average Number of employees:</b>
                      <table className='table table-striped table-borderless mb-0'>
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Full-time W2 Employees</th>
                            <th>Part-time W2 Employees</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>2019</th>
                            <td>{appData.FTE2019}</td>
                            <td>{appData.PTE2019}</td>
                          </tr>
                          <tr>
                            <th>2020</th>
                            <td>{appData.FTE2020}</td>
                            <td>{appData.PTE2020}</td>
                          </tr>
                          <tr>
                            <th>2021</th>
                            <td>{appData.FTE2021}</td>
                            <td>{appData.PTE2021}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='mt-3'>{appData.ProvidedAvNoOfFTE && "Provided the average number of full-time employees per the definition provided"}</div>
                    </Col>
                  </Row>
                  <section className='bdr-top mt-3'>
                    <div className='mb-3'><span className='label'>Names of employees who own more than 50% of the business</span><div className='border p-2 bg-white'>{appData.EmployeeNames ? <span dangerouslySetInnerHTML={{__html: appData.EmployeeNames}}/> : <>&nbsp;</>}</div>
                    </div>
                    {/* <div><span className='label'>Name of all employee(s) who are related to any owner, name of the related owner and the relationship between such employee(s) and owner(s)</span><div className='border p-2 bg-white'>{appData.EmployeeNamesRelatedOwnerRelationship ? <span dangerouslySetInnerHTML={{__html: appData.EmployeeNamesRelatedOwnerRelationship}} /> : <>&nbsp;</>}</div></div> */}
                  </section>
                </div>
              </section>
            </Container>
          </section>
          {/* Onboarding Questionnaire */}
          {userPrivilegedPIIInfo && <section className='bg-gray'>
            <Container fluid>
              <h6>Onboarding Questionnaire</h6>
              <div><b>ERC Onboarding Questionnaire</b></div>
              {appData.ERCOnboardingQuestionnaireUpload && <div>
                {appData.ERCOnboardingQuestionnaireUpload}<br/>
                <button type="button" className='view-file' title={appData.ERCOnboardingQuestionnaireUpload} onClick={() => showFile('ERCOnboardingQuestionnaireUpload')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ERCOnboardingQuestionnaireUpload} onClick={() => downloadFile('ERCOnboardingQuestionnaireUpload')}><i className="fas fa-file-download"></i></button>
              </div>}
            </Container>
          </section>}
          {/* Approve onboarding */}
          {<section className='bg-gray'>
            <Container fluid>
              {appData.OnboardingSubmitted ? <>
                {(appData.OnboardingTermsOfService && appData.OnboardingPrivacyPolicy) && <p>* The user agrees to all Onboarding Terms and Conditions and also the Onboarding Privacy policy.</p>}
                {(appData.OnboardingCertificationOfData) && <p>* The user certifies that all the information submitted in the onboarding form is correct.</p>}
                {!appData.OnboardingAccepted ? <div>
                  <Button variant="primary" type="button" onClick={(e) => handleSetData('submit_for_onboarding', 'OnboardingAccepted', true, e)} disabled={!userPrivilegedOnboardingApprove}>Accept Onboarding Details</Button>
                </div> : <div><img src={require('./../assets/images/confirmation-icon.png')} className='me-2' /><b>Onboarding Details Accepted</b></div>}
              </> : <div className='txt-red'><b>User onboarding submission pending!</b></div>}
            </Container>
          </section>}
          {/* Engagement Agreement */}
          {(/* appData.OnboardingAccepted &&  */userPrivilegedSendEngagementAgreement) && <section className='bg-gray'>
            <Container fluid>
              <h6>Engagement Agreement</h6>
              {/* <Row className='align-items-center'>
                <Col>
                  <div className='d-flex align-items-center'>
                    <div className='me-2'>
                      <Form.Select onChange={handleSetEngagementAgreementTemplateName} value={appData.EngagementAgreementTemplateName || ""} disabled={engagementSignatureStatus.value}>
                        <option value="">Select file</option>
                        <option value="EY">EY</option>
                        <option value="ERTCF">ERTCF</option>
                      </Form.Select>
                      // <div className="position-relative d-inline-block">
                      //   <Form.Label htmlFor="EngagementAgreementDocument" className='upload upload-default'>
                      //     <span className="txt-upload">Upload</span>
                      //   </Form.Label>
                      //   <Form.Control id="EngagementAgreementDocument" name="EngagementAgreementDocument" type="file" accept=".pdf" onChange={(e) => handleUpload('engagement_agreement', e)} />
                      // </div>
                    </div>
                    // {appData.EngagementAgreementDocument && appData.EngagementAgreementDocument}
                    // {appData.EngagementAgreementDocument && <button type="button" className='view-file p-0 ms-3' title={appData.EngagementAgreementDocument} onClick={() => showFile('EngagementAgreementDocument')}><img src={require('./../assets/images/button-icon.png')} /></button>}
                  </div>
                </Col>
                <Col>{(engagementSignatureStatus.value === 'sent' || engagementSignatureStatus.value === 'signed') && <>
                  <span className='label'>Status</span><br />
                  {engagementSignatureStatus.value === 'signed' ? <>
                    Engagement agreement <b>signed</b> by customer<br/>
                    {appData.EngagementAgreementDocumentSigned && appData.EngagementAgreementDocumentSigned}
                    {appData.EngagementAgreementDocumentSigned && <button type="button" className='view-file p-0 ms-3' title={appData.EngagementAgreementDocumentSigned} onClick={() => showFile('EngagementAgreementDocumentSigned')}><img src={require('./../assets/images/button-icon.png')} /></button>}</>
                  :
                  (engagementSignatureStatus.value === 'sent' && 'Sent to customer for signing')}
                </>}
                {engagementSignatureStatus.value === 'toSend' && <>
                  <span className='label'>Status</span><br />Sending process initiated
                </>}
                </Col>
              </Row>
              {!engagementSignatureStatus.value && <div className='mt-3'><Button variant="primary" disabled={appData.EngagementAgreementTemplateName ? false : true} onClick={(e) => handleSetData('engagement_agreement', 'EngagementSignatureStatus', {value: "toSend"}, e)}>Send to Customer</Button></div>} */}
              <div>
                {!appData.EngagementAgreementWSSentToCustomer && <div className="position-relative d-inline-block">
                  <Form.Label htmlFor="EngagementAgreementWSDocument" className='upload upload-default'>
                    <span className="txt-upload">Upload</span>
                  </Form.Label>
                  <Form.Control id="EngagementAgreementWSDocument" name="EngagementAgreementWSDocument" type="file" accept=".pdf" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('engagement_agreement', e)} />
                </div>}
                {appData.EngagementAgreementWSDocument && <div>
                  {appData.EngagementAgreementWSDocument}<br/>
                  <button type="button" className='view-file' title={appData.EngagementAgreementWSDocument} onClick={() => showFile('EngagementAgreementWSDocument')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EngagementAgreementWSDocument} onClick={() => downloadFile('EngagementAgreementWSDocument')}><i className="fas fa-file-download"></i></button>
                </div>}
              </div>
              {!appData.EngagementAgreementWSSentToCustomer && <div className='mt-3'><Button variant="primary" type="submit" disabled={(appData.OnboardingAccepted && appData.EngagementAgreementWSDocument) ? false : true} onClick={(e) => handleSetData('engagement_agreement', 'EngagementAgreementWSSentToCustomer', true, e)}>Send to Customer</Button></div>}
              {(appData.EngagementAgreementWSSentToCustomer && !appData.EngagementAgreementWSDocumentSigned) && <div className='mt-4'><span className='label'>Status:</span> Engagement Agreement sent to customer</div>}
              {appData.EngagementAgreementWSDocumentSigned && <div className='d-flex align-items-center'>
                <div className='mt-4'><span className='label'>Status:</span> Engagement Agreement signed by customer</div>
                <div className='mx-3'>
                  {appData.EngagementAgreementWSDocumentSigned}<br/>
                  <button type="button" className='view-file' title={appData.EngagementAgreementWSDocumentSigned} onClick={() => showFile('EngagementAgreementWSDocumentSigned')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EngagementAgreementWSDocumentSigned} onClick={() => downloadFile('EngagementAgreementWSDocumentSigned')}><i className="fas fa-file-download"></i></button>
                </div>
                {!appData.EngagementAgreementWSAccepted ? <div className='ms-5'>
                  <Button variant="primary" type="button" onClick={(e) => handleSetData('engagement_agreement', 'EngagementAgreementWSAccepted', true, e)} /* disabled={!appData.EngagementAgreementWSSubmitted} */>Accept</Button>
                </div> : <div className='ms-5'><img src={require('./../assets/images/confirmation-icon.png')} className='me-2' /><b>Accepted</b></div>}
              </div>}
            </Container>
          </section>}
          {/* appData.EngagementAgreementWSAccepted && */ <>
            {/* Covid-19 */}
            {appData.Industry === 'Banks' ? <section className='bg-gray'>
              <Container fluid>
                <h6>Covid-19 (Banks)</h6>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Location and Facilities Considerations</b></div>
                <div className="sub-group border-bottom mb-3 pb-3">
                  <table className="table table-striped table-bordered mb-0 border">
                    <thead>
                      <tr>
                        <th width="60%">Question</th>
                        <th width="25%">Answer</th>
                        <th>Supporting document if any</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>In which states and cities does the company operate? Provide location listing showing location sites/description.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.LocationListing}}/></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Were certain locations impacted more than others? Which ones?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.LocationsImpacted}}/></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>If the bank has locations in multiple states and cities, did the company pass a policy to treat all locations (e.g., branches) in the same fashion in terms of hours, cleaning, social distancing etc.?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.LocationsPolicy}}/></td>
                        <td>{appData.LocationsPolicyDoc && <><button type="button" className='view-file' title={appData.LocationsPolicyDoc} onClick={() => showFile('LocationsPolicyDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.LocationsPolicyDoc} onClick={() => downloadFile('LocationsPolicyDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Were any locations shut-down initially in 2020 or throughout 2021 due to COVID-19 governmental mandates, OSHA, or COVID outbreaks?</td>
                        <td>{appData.AnyLocationsShutdown}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mb-4 mt-4'>
                    <span className='label'>Approximate dates when the Company closed its offices due to COVID-19:</span>
                    <div className='sub-group'>
                      {locationDisruptionData.length > 0 && locationDisruptionData.sort( (a,b) => b.timestamp - a.timestamp ).map((item,index) => {
                        let quarters = item.DisruptionQuarters;
                        return (<div className="border-top mt-2 pt-2" key={index}>
                          <fieldset>
                            <p className='mb-1'><b>Periods of disruption {index + 1}</b></p>
                            <Row key={index}>
                              <Col xs={2}>
                                <span className='label'>Location</span><br/>{item.DisruptionLocation}
                              </Col>
                              <Col xs={2}>
                                <span className='label'>Start Date</span><br/>{item.DisruptionLocStartDate}
                              </Col>
                              <Col xs={2}>
                                <span className='label'>End Date</span><br/>{item.DisruptionLocEndDate}
                              </Col>
                              <Col xs={4}><span className='label'>Quarters affected:</span><br/>{quarters && quarters.map((q,i) => <>{i+1 === quarters.length ? q : <>{q}, </>}</>)}</Col>
                            </Row>
                            {item.DisruptionLocComments && <Row>
                              <Col>
                                <span className='label'>Explanation</span>
                                <div className='border p-2 bg-white'><span dangerouslySetInnerHTML={{__html: item.DisruptionLocComments}}/></div>
                              </Col>
                            </Row>}
                          </fieldset>
                        </div>)
                      })}
                    </div>
                  </div>
                  <table className="table table-striped table-bordered mb-0 mt-3 border">
                    <thead>
                      <tr>
                        <th width="60%">Question</th>
                        <th width="25%">Answer</th>
                        <th>Supporting document if any</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="3">Company's initial and ongoing responses to COVID such as employee announcements or emails concerning:</td>
                      </tr>
                      <tr>
                        <td>(a) Directive for employees to work remotely.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.DirectiveWorkRemotely}}/></td>
                        <td>{appData.DirectiveWorkRemotelyDoc && <><button type="button" className='view-file' title={appData.DirectiveWorkRemotelyDoc} onClick={() => showFile('DirectiveWorkRemotelyDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.DirectiveWorkRemotelyDoc} onClick={() => downloadFile('DirectiveWorkRemotelyDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(b) Company reopening plans for 2020 and 2021 including any pauses or reversals of re-openings due to COVID spikes.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.CompanyReopeningPlans}} /></td>
                        <td>{appData.CompanyReopeningPlansDoc && <><button type="button" className='view-file' title={appData.CompanyReopeningPlansDoc} onClick={() => showFile('CompanyReopeningPlansDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.CompanyReopeningPlansDoc} onClick={() => downloadFile('CompanyReopeningPlansDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(c) Cancellation of travel/conferences/meetings.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.Cancellation}}/></td>
                        <td>{appData.CancellationDoc && <><button type="button" className='view-file' title={appData.CancellationDoc} onClick={() => showFile('CancellationDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.CancellationDoc} onClick={() => downloadFile('CancellationDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>For employees who are working from home, did the Bank provide these employees any equipment (i.e., laptops, extra monitors, furniture, etc.) to facilitate their working from home?</td>
                        <td>{appData.BankProvidedEquipment}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Please provide the approximate costs the Company incurred to provide this equipment and furnishings (high level cost estimates are sufficient).</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.BankProvidedEquipmentCost}}/></td>
                        <td>{appData.BankProvidedEquipmentDoc && <><button type="button" className='view-file' title={appData.BankProvidedEquipmentDoc} onClick={() => showFile('BankProvidedEquipmentDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.BankProvidedEquipmentDoc} onClick={() => downloadFile('BankProvidedEquipmentDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Operations Considerations</b></div>
                <div className="sub-group border-bottom mb-3 pb-3">
                  <table className="table table-striped table-bordered mb-0 border">
                    <thead>
                      <tr>
                        <th width="60%">Question</th>
                        <th width="25%">Answer</th>
                        <th>Supporting document if any</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>How did the Company's operations/activities change starting in the second quarter 2020 through 2021 compared to 2019?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.CompanyOperationsChangeCause}}/></td>
                        <td>{appData.CompanyOperationsChangeCauseDoc && <><button type="button" className='view-file' title={appData.CompanyOperationsChangeCauseDoc} onClick={() => showFile('CompanyOperationsChangeCauseDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.CompanyOperationsChangeCauseDoc} onClick={() => downloadFile('CompanyOperationsChangeCauseDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Did branch or office location operating hours change? Were any branches closed either fully or partially (i.e. closed lobbies, etc.) in 2020 and 2021?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.OperatingHoursChangeCause}}/></td>
                        <td>{appData.OperatingHoursChangeCauseDoc && <><button type="button" className='view-file' title={appData.OperatingHoursChangeCauseDoc} onClick={() => showFile('OperatingHoursChangeCauseDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.OperatingHoursChangeCauseDoc} onClick={() => downloadFile('OperatingHoursChangeCauseDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Were there any mandates to clean the lobby where the branch had to be shut down earlier than normal?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.MandatesBranchShutdown}}/></td>
                        <td>{appData.MandatesBranchShutdownDoc && <><button type="button" className='view-file' title={appData.MandatesBranchShutdownDoc} onClick={() => showFile('MandatesBranchShutdownDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.MandatesBranchShutdownDoc} onClick={() => downloadFile('MandatesBranchShutdownDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>How were branch operations impacted related to social distancing required in lobbies or changes for how customers interacted with tellers?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.BranchOperationsImpacted}}/></td>
                        <td>{appData.BranchOperationsImpactedDoc && <><button type="button" className='view-file' title={appData.BranchOperationsImpactedDoc} onClick={() => showFile('BranchOperationsImpactedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.BranchOperationsImpactedDoc} onClick={() => downloadFile('BranchOperationsImpactedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>How did the interaction between customers and loan officers or other banking personnel change within branch locations?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.InteractionChange}}/></td>
                        <td>{appData.InteractionChangeDoc && <><button type="button" className='view-file' title={appData.InteractionChangeDoc} onClick={() => showFile('InteractionChangeDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.InteractionChangeDoc} onClick={() => downloadFile('InteractionChangeDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>How was facility management impacted including the need for cleaning and sanitation? Did typical hours shift due to the necessary sanitation requirements or did any closures of locations occur?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.ImpactOnFacilityManagement}}/></td>
                        <td>{appData.ImpactOnFacilityManagementDoc && <><button type="button" className='view-file' title={appData.ImpactOnFacilityManagementDoc} onClick={() => showFile('ImpactOnFacilityManagementDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ImpactOnFacilityManagementDoc} onClick={() => downloadFile('ImpactOnFacilityManagementDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>If your bank operates a call center, what changes were made to allow for social distancing, cleaning/sanitation, or other requirements based on the pandemic?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.ChangesMadePandemic}}/></td>
                        <td>{appData.ChangesMadePandemicDoc && <><button type="button" className='view-file' title={appData.ChangesMadePandemicDoc} onClick={() => showFile('ChangesMadePandemicDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ChangesMadePandemicDoc} onClick={() => downloadFile('ChangesMadePandemicDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>How did travel limitations impact bank personnel, sales or marketing teams including attendance at community events?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.TravelLimitationsImpact}}/></td>
                        <td>{appData.TravelLimitationsImpactDoc && <><button type="button" className='view-file' title={appData.TravelLimitationsImpactDoc} onClick={() => showFile('TravelLimitationsImpactDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.TravelLimitationsImpactDoc} onClick={() => downloadFile('TravelLimitationsImpactDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Staffing Considerations</b></div>
                <div className="sub-group border-bottom mb-3 pb-3">
                  <table className="table table-striped table-bordered mb-0 border">
                    <thead>
                      <tr>
                        <th width="60%">Question</th>
                        <th width="25%">Answer</th>
                        <th>Supporting document if any</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>How were corporate office staff impacted?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.CorporateOfficeStaffImpacted}}/></td>
                        <td>{appData.CorporateOfficeStaffImpactedDoc && <><button type="button" className='view-file' title={appData.CorporateOfficeStaffImpactedDoc} onClick={() => showFile('CorporateOfficeStaffImpactedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.CorporateOfficeStaffImpactedDoc} onClick={() => downloadFile('CorporateOfficeStaffImpactedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Was there any impact to support functions such as mail room staff, food service, procurement or other key areas needed to support business activity?</td>
                        <td>{appData.ImpactToSupportFunctions} <span dangerouslySetInnerHTML={{__html: appData.ImpactToSupportFunctionsExplanation}}/></td>
                        <td>{appData.ImpactToSupportFunctionsExplanationDoc && <><button type="button" className='view-file' title={appData.ImpactToSupportFunctionsExplanationDoc} onClick={() => showFile('ImpactToSupportFunctionsExplanationDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ImpactToSupportFunctionsExplanationDoc} onClick={() => downloadFile('ImpactToSupportFunctionsExplanationDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Were anticipated hiring plans affected?</td>
                        <td>{appData.AnticipatedHiringPlansAffected} <span dangerouslySetInnerHTML={{__html: appData.AnticipatedHiringPlansAffectedExplanation}}/></td>
                        <td>{appData.AnticipatedHiringPlansAffectedExplanationDoc && <><button type="button" className='view-file' title={appData.AnticipatedHiringPlansAffectedExplanationDoc} onClick={() => showFile('AnticipatedHiringPlansAffectedExplanationDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.AnticipatedHiringPlansAffectedExplanationDoc} onClick={() => downloadFile('AnticipatedHiringPlansAffectedExplanationDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td colSpan="3">Did the Company need to reduce in person staffing?</td>
                      </tr>
                      <tr>
                        <td>(a) Due to the social distancing requirements to keep six feet apart?</td>
                        <td>{appData.SocialDistanceRequirements}</td>
                        <td>{appData.SocialDistanceRequirementsDoc && <><button type="button" className='view-file' title={appData.SocialDistanceRequirementsDoc} onClick={() => showFile('SocialDistanceRequirementsDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.SocialDistanceRequirementsDoc} onClick={() => downloadFile('SocialDistanceRequirementsDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(b) How did the number of employees that could be in the office at any one time change?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.EmployeesNumberChange}}/></td>
                        <td>{appData.EmployeesNumberChangeDoc && <><button type="button" className='view-file' title={appData.EmployeesNumberChangeDoc} onClick={() => showFile('EmployeesNumberChangeDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeesNumberChangeDoc} onClick={() => downloadFile('EmployeesNumberChangeDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(c) Was there a reduction in services or goods offered to your customers?</td>
                        <td>{appData.ReductionInServices} <span dangerouslySetInnerHTML={{__html: appData.ReductionInServicesExplanation}}/></td>
                        <td>{appData.ReductionInServicesExplanationDoc && <><button type="button" className='view-file' title={appData.ReductionInServicesExplanationDoc} onClick={() => showFile('ReductionInServicesExplanationDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ReductionInServicesExplanationDoc} onClick={() => downloadFile('ReductionInServicesExplanationDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(d) Please list any conferences or external meetings Company employees could not attend in 2020 and/or 2021 due to COVID limitations.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.ConferencesNotAttended}}/></td>
                        <td>{appData.ConferencesNotAttendedDoc && <><button type="button" className='view-file' title={appData.ConferencesNotAttendedDoc} onClick={() => showFile('ConferencesNotAttendedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.ConferencesNotAttendedDoc} onClick={() => downloadFile('ConferencesNotAttendedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Were employees given any additional paid time off due to Covid, the need to quarantine or due to sickness?</td>
                        <td>{appData.AdditionalPaidTimeOff}</td>
                        <td>{appData.AdditionalPaidTimeOffDoc && <><button type="button" className='view-file' title={appData.AdditionalPaidTimeOffDoc} onClick={() => showFile('AdditionalPaidTimeOffDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.AdditionalPaidTimeOffDoc} onClick={() => downloadFile('AdditionalPaidTimeOffDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Did IT track employees who reported disruptions due to technology issues?</td>
                        <td>{appData.ITTrackedEmployees}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Please provide details available on employee reported outages.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.EmployeeReportedOutagesDetails}}/></td>
                        <td>{appData.EmployeeReportedOutagesDoc && <><button type="button" className='view-file' title={appData.EmployeeReportedOutagesDoc} onClick={() => showFile('EmployeeReportedOutagesDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeeReportedOutagesDoc} onClick={() => downloadFile('EmployeeReportedOutagesDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='mb-2 mt-4'>
                    <span className='label'>Based on the impact above, please indicate the timeframes when the disruption was experienced.</span>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Quarters</th>
                          <th>Disruption experienced?</th>
                          <th>Disrupted the entire quarter?</th>
                          <th>Starting date</th>
                          <th>Ending date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Q1 2020 (Jan 1 - Mar  31)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2020Q1}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2020Q1}</td>
                          <td>{staffingDisruptionData.StartDate2020Q1}</td>
                          <td>{staffingDisruptionData.EndDate2020Q1}</td>
                        </tr>
                        <tr>
                          <th>Q2 2020 (Apr 1 - Jun  30)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2020Q2}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2020Q2}</td>
                          <td>{staffingDisruptionData.StartDate2020Q2}</td>
                          <td>{staffingDisruptionData.EndDate2020Q2}</td>
                        </tr>
                        <tr>
                          <th>Q3 2020 (Jul 1 - Sep  30)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2020Q3}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2020Q3}</td>
                          <td>{staffingDisruptionData.StartDate2020Q3}</td>
                          <td>{staffingDisruptionData.EndDate2020Q3}</td>
                        </tr>
                        <tr>
                          <th>Q4 2020 (Oct 1 - Dec  31)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2020Q4}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2020Q4}</td>
                          <td>{staffingDisruptionData.StartDate2020Q4}</td>
                          <td>{staffingDisruptionData.EndDate2020Q4}</td>
                        </tr>
                        <tr>
                          <th>Q1 2021 (Jan 1 - Mar  31)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2021Q1}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2021Q1}</td>
                          <td>{staffingDisruptionData.StartDate2021Q1}</td>
                          <td>{staffingDisruptionData.EndDate2021Q1}</td>
                        </tr>
                        <tr>
                          <th>Q2 2021 (Apr 1 - Jun  30)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2021Q2}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2021Q2}</td>
                          <td>{staffingDisruptionData.StartDate2021Q2}</td>
                          <td>{staffingDisruptionData.EndDate2021Q2}</td>
                        </tr>
                        <tr>
                          <th>Q3 2021 (Jul 1 - Sep  30)</th>
                          <td>{staffingDisruptionData.DisruptionExperienced2021Q3}</td>
                          <td>{staffingDisruptionData.DisruptedEntireQuarter2021Q3}</td>
                          <td>{staffingDisruptionData.StartDate2021Q3}</td>
                          <td>{staffingDisruptionData.EndDate2021Q3}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Loan Origination */}
                {appData.Industry === 'Banks' && <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Loan Origination and Account Opening Details</b></div>
                  <div className="sub-group mb-3">
                    <div className='mb-2'><b>Loan Origination Data (excluding PPP Loans)</b></div>
                    <table className='table table-docs table-borderless mt-2 mb-4'>
                      <thead>
                        <tr>
                          <th>2019 Q1</th>
                          <th>2019 Q2</th>
                          <th>2019 Q3</th>
                          <th>2019 Q4</th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>{appData.LoanOrigination2019Q1Amount && <>$ {appData.LoanOrigination2019Q1Amount}<br/></>}
                            {appData.LoanOrigination2019Q1File ? <><button className='view-file' title={appData.LoanOrigination2019Q1File} onClick={() => showFile('LoanOrigination2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2019Q1File} onClick={() => downloadFile('LoanOrigination2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2019Q2Amount && <>$ {appData.LoanOrigination2019Q2Amount}<br/></>}
                            {appData.LoanOrigination2019Q2File ? <><button className='view-file' title={appData.LoanOrigination2019Q2File} onClick={() => showFile('LoanOrigination2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2019Q2File} onClick={() => downloadFile('LoanOrigination2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2019Q3Amount && <>$ {appData.LoanOrigination2019Q3Amount}<br/></>}
                            {appData.LoanOrigination2019Q3File ? <><button className='view-file' title={appData.LoanOrigination2019Q3File} onClick={() => showFile('LoanOrigination2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2019Q3File} onClick={() => downloadFile('LoanOrigination2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2019Q4Amount && <>$ {appData.LoanOrigination2019Q4Amount}<br/></>}
                            {appData.LoanOrigination2019Q4File ? <><button className='view-file' title={appData.LoanOrigination2019Q4File} onClick={() => showFile('LoanOrigination2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2019Q4File} onClick={() => downloadFile('LoanOrigination2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2020Q1Amount && <>$ {appData.LoanOrigination2020Q1Amount}<br/></>}
                            {appData.LoanOrigination2020Q1File ? <><button className='view-file' title={appData.LoanOrigination2020Q1File} onClick={() => showFile('LoanOrigination2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2020Q1File} onClick={() => downloadFile('LoanOrigination2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2020Q2Amount && <>$ {appData.LoanOrigination2020Q2Amount}<br/></>}
                            {appData.LoanOrigination2020Q2File ? <><button className='view-file' title={appData.LoanOrigination2020Q2File} onClick={() => showFile('LoanOrigination2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2020Q2File} onClick={() => downloadFile('LoanOrigination2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2020Q3Amount && <>$ {appData.LoanOrigination2020Q3Amount}<br/></>}
                            {appData.LoanOrigination2020Q3File ? <><button className='view-file' title={appData.LoanOrigination2020Q3File} onClick={() => showFile('LoanOrigination2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2020Q3File} onClick={() => downloadFile('LoanOrigination2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2020Q4Amount && <>$ {appData.LoanOrigination2020Q4Amount}<br/></>}
                            {appData.LoanOrigination2020Q4File ? <><button className='view-file' title={appData.LoanOrigination2020Q4File} onClick={() => showFile('LoanOrigination2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2020Q4File} onClick={() => downloadFile('LoanOrigination2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2021Q1Amount && <>$ {appData.LoanOrigination2021Q1Amount}<br/></>}
                            {appData.LoanOrigination2021Q1File ? <><button className='view-file' title={appData.LoanOrigination2021Q1File} onClick={() => showFile('LoanOrigination2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2021Q1File} onClick={() => downloadFile('LoanOrigination2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2021Q2Amount && <>$ {appData.LoanOrigination2021Q2Amount}<br/></>}
                            {appData.LoanOrigination2021Q2File ? <><button className='view-file' title={appData.LoanOrigination2021Q2File} onClick={() => showFile('LoanOrigination2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2021Q2File} onClick={() => downloadFile('LoanOrigination2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2021Q3Amount && <>$ {appData.LoanOrigination2021Q3Amount}<br/></>}
                            {appData.LoanOrigination2021Q3File ? <><button className='view-file' title={appData.LoanOrigination2021Q3File} onClick={() => showFile('LoanOrigination2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2021Q3File} onClick={() => downloadFile('LoanOrigination2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.LoanOrigination2021Q4Amount && <>$ {appData.LoanOrigination2021Q4Amount}<br/></>}
                            {appData.LoanOrigination2021Q4File ? <><button className='view-file' title={appData.LoanOrigination2021Q4File} onClick={() => showFile('LoanOrigination2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.LoanOrigination2021Q4File} onClick={() => downloadFile('LoanOrigination2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="sub-group mb-3">
                    <div className='mb-2'><b>PPP Loan Origination Data</b></div>
                    <table className='table table-docs table-borderless mt-2 mb-4'>
                      <thead>
                        <tr>
                          <th>2019 Q1</th>
                          <th>2019 Q2</th>
                          <th>2019 Q3</th>
                          <th>2019 Q4</th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>{appData.PPPLoanOrigination2019Q1Amount && <>$ {appData.PPPLoanOrigination2019Q1Amount}<br/></>}
                            {appData.PPPLoanOrigination2019Q1File ? <><button className='view-file' title={appData.PPPLoanOrigination2019Q1File} onClick={() => showFile('PPPLoanOrigination2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2019Q1File} onClick={() => downloadFile('PPPLoanOrigination2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2019Q2Amount && <>$ {appData.PPPLoanOrigination2019Q2Amount}<br/></>}
                            {appData.PPPLoanOrigination2019Q2File ? <><button className='view-file' title={appData.PPPLoanOrigination2019Q2File} onClick={() => showFile('PPPLoanOrigination2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2019Q2File} onClick={() => downloadFile('PPPLoanOrigination2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2019Q3Amount && <>$ {appData.PPPLoanOrigination2019Q3Amount}<br/></>}
                            {appData.PPPLoanOrigination2019Q3File ? <><button className='view-file' title={appData.PPPLoanOrigination2019Q3File} onClick={() => showFile('PPPLoanOrigination2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2019Q3File} onClick={() => downloadFile('PPPLoanOrigination2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2019Q4Amount && <>$ {appData.PPPLoanOrigination2019Q4Amount}<br/></>}
                            {appData.PPPLoanOrigination2019Q4File ? <><button className='view-file' title={appData.PPPLoanOrigination2019Q4File} onClick={() => showFile('PPPLoanOrigination2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2019Q4File} onClick={() => downloadFile('PPPLoanOrigination2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2020Q1Amount && <>$ {appData.PPPLoanOrigination2020Q1Amount}<br/></>}
                            {appData.PPPLoanOrigination2020Q1File ? <><button className='view-file' title={appData.PPPLoanOrigination2020Q1File} onClick={() => showFile('PPPLoanOrigination2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2020Q1File} onClick={() => downloadFile('PPPLoanOrigination2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2020Q2Amount && <>$ {appData.PPPLoanOrigination2020Q2Amount}<br/></>}
                            {appData.PPPLoanOrigination2020Q2File ? <><button className='view-file' title={appData.PPPLoanOrigination2020Q2File} onClick={() => showFile('PPPLoanOrigination2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2020Q2File} onClick={() => downloadFile('PPPLoanOrigination2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2020Q3Amount && <>$ {appData.PPPLoanOrigination2020Q3Amount}<br/></>}
                            {appData.PPPLoanOrigination2020Q3File ? <><button className='view-file' title={appData.PPPLoanOrigination2020Q3File} onClick={() => showFile('PPPLoanOrigination2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2020Q3File} onClick={() => downloadFile('PPPLoanOrigination2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2020Q4Amount && <>$ {appData.PPPLoanOrigination2020Q4Amount}<br/></>}
                            {appData.PPPLoanOrigination2020Q4File ? <><button className='view-file' title={appData.PPPLoanOrigination2020Q4File} onClick={() => showFile('PPPLoanOrigination2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2020Q4File} onClick={() => downloadFile('PPPLoanOrigination2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2021Q1Amount && <>$ {appData.PPPLoanOrigination2021Q1Amount}<br/></>}
                            {appData.PPPLoanOrigination2021Q1File ? <><button className='view-file' title={appData.PPPLoanOrigination2021Q1File} onClick={() => showFile('PPPLoanOrigination2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2021Q1File} onClick={() => downloadFile('PPPLoanOrigination2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2021Q2Amount && <>$ {appData.PPPLoanOrigination2021Q2Amount}<br/></>}
                            {appData.PPPLoanOrigination2021Q2File ? <><button className='view-file' title={appData.PPPLoanOrigination2021Q2File} onClick={() => showFile('PPPLoanOrigination2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2021Q2File} onClick={() => downloadFile('PPPLoanOrigination2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2021Q3Amount && <>$ {appData.PPPLoanOrigination2021Q3Amount}<br/></>}
                            {appData.PPPLoanOrigination2021Q3File ? <><button className='view-file' title={appData.PPPLoanOrigination2021Q3File} onClick={() => showFile('PPPLoanOrigination2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2021Q3File} onClick={() => downloadFile('PPPLoanOrigination2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.PPPLoanOrigination2021Q4Amount && <>$ {appData.PPPLoanOrigination2021Q4Amount}<br/></>}
                            {appData.PPPLoanOrigination2021Q4File ? <><button className='view-file' title={appData.PPPLoanOrigination2021Q4File} onClick={() => showFile('PPPLoanOrigination2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPPLoanOrigination2021Q4File} onClick={() => downloadFile('PPPLoanOrigination2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="sub-group border-bottom mb-3">
                    <div className='mb-2'><b>Bank Account Opening Data</b></div>
                    <table className='table table-docs table-borderless mt-2 mb-4'>
                      <thead>
                        <tr>
                          <th>2019 Q1</th>
                          <th>2019 Q2</th>
                          <th>2019 Q3</th>
                          <th>2019 Q4</th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>{appData.BankAccountOpening2019Q1NoOfAccounts && <>{appData.BankAccountOpening2019Q1NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2019Q1File ? <><button className='view-file' title={appData.BankAccountOpening2019Q1File} onClick={() => showFile('BankAccountOpening2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2019Q1File} onClick={() => downloadFile('BankAccountOpening2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2019Q2NoOfAccounts && <>{appData.BankAccountOpening2019Q2NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2019Q2File ? <><button className='view-file' title={appData.BankAccountOpening2019Q2File} onClick={() => showFile('BankAccountOpening2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2019Q2File} onClick={() => downloadFile('BankAccountOpening2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2019Q3NoOfAccounts && <>{appData.BankAccountOpening2019Q3NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2019Q3File ? <><button className='view-file' title={appData.BankAccountOpening2019Q3File} onClick={() => showFile('BankAccountOpening2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2019Q3File} onClick={() => downloadFile('BankAccountOpening2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2019Q4NoOfAccounts && <>{appData.BankAccountOpening2019Q4NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2019Q4File ? <><button className='view-file' title={appData.BankAccountOpening2019Q4File} onClick={() => showFile('BankAccountOpening2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2019Q4File} onClick={() => downloadFile('BankAccountOpening2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2020Q1NoOfAccounts && <>{appData.BankAccountOpening2020Q1NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2020Q1File ? <><button className='view-file' title={appData.BankAccountOpening2020Q1File} onClick={() => showFile('BankAccountOpening2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2020Q1File} onClick={() => downloadFile('BankAccountOpening2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2020Q2NoOfAccounts && <>{appData.BankAccountOpening2020Q2NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2020Q2File ? <><button className='view-file' title={appData.BankAccountOpening2020Q2File} onClick={() => showFile('BankAccountOpening2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2020Q2File} onClick={() => downloadFile('BankAccountOpening2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2020Q3NoOfAccounts && <>{appData.BankAccountOpening2020Q3NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2020Q3File ? <><button className='view-file' title={appData.BankAccountOpening2020Q3File} onClick={() => showFile('BankAccountOpening2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2020Q3File} onClick={() => downloadFile('BankAccountOpening2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2020Q4NoOfAccounts && <>{appData.BankAccountOpening2020Q4NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2020Q4File ? <><button className='view-file' title={appData.BankAccountOpening2020Q4File} onClick={() => showFile('BankAccountOpening2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2020Q4File} onClick={() => downloadFile('BankAccountOpening2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2021Q1NoOfAccounts && <>{appData.BankAccountOpening2021Q1NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2021Q1File ? <><button className='view-file' title={appData.BankAccountOpening2021Q1File} onClick={() => showFile('BankAccountOpening2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2021Q1File} onClick={() => downloadFile('BankAccountOpening2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2021Q2NoOfAccounts && <>{appData.BankAccountOpening2021Q2NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2021Q2File ? <><button className='view-file' title={appData.BankAccountOpening2021Q2File} onClick={() => showFile('BankAccountOpening2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2021Q2File} onClick={() => downloadFile('BankAccountOpening2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2021Q3NoOfAccounts && <>{appData.BankAccountOpening2021Q3NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2021Q3File ? <><button className='view-file' title={appData.BankAccountOpening2021Q3File} onClick={() => showFile('BankAccountOpening2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2021Q3File} onClick={() => downloadFile('BankAccountOpening2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.BankAccountOpening2021Q4NoOfAccounts && <>{appData.BankAccountOpening2021Q4NoOfAccounts}<br/></>}
                            {appData.BankAccountOpening2021Q4File ? <><button className='view-file' title={appData.BankAccountOpening2021Q4File} onClick={() => showFile('BankAccountOpening2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.BankAccountOpening2021Q4File} onClick={() => downloadFile('BankAccountOpening2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>}

                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Human Resources/Payroll Considerations for 2020/2021</b></div>
                <div className="sub-group">
                  <table className="table table-striped table-bordered mb-0 border">
                    <thead>
                      <tr>
                        <th width="60%">Question</th>
                        <th width="25%">Answer</th>
                        <th>Supporting document if any</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Did the Company have any employees who could not work from home?</td>
                        <td colSpan="2">{appData.EmployeesCouldNotWfh}</td>
                      </tr>
                      <tr>
                        <td>(a) How many such employees could not work from home? And for what period?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.EmployeesNumberPeriod}}/></td>
                        <td>{appData.EmployeesCouldNotWfhDoc && <><button type="button" className='view-file' title={appData.EmployeesCouldNotWfhDoc} onClick={() => showFile('EmployeesCouldNotWfhDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeesCouldNotWfhDoc} onClick={() => downloadFile('EmployeesCouldNotWfhDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(b) Were they paid for the time they could not work?</td>
                        <td>{appData.PaidIfNotWorked}</td>
                        <td>{appData.PaidIfNotWorkedDoc && <><button type="button" className='view-file' title={appData.PaidIfNotWorkedDoc} onClick={() => showFile('PaidIfNotWorkedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.PaidIfNotWorkedDoc} onClick={() => downloadFile('PaidIfNotWorkedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Were any employees furloughed in 2020 or 2021?</td>
                        <td colSpan="2">{appData.EmployeesFurloughed}</td>
                      </tr>
                      <tr>
                        <td>(a) How many such employees were furloughed in 2020 or 2021?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.EmployeesNumberFurloughedPeriod}}/></td>
                        <td>{appData.EmployeesFurloughedDoc && <><button type="button" className='view-file' title={appData.EmployeesFurloughedDoc} onClick={() => showFile('EmployeesFurloughedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeesFurloughedDoc} onClick={() => downloadFile('EmployeesFurloughedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>(b) Provide the estimated average healthcare cost that the Company paid on behalf of the furloughed population.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.HealthcareCost}}/></td>
                        <td>{appData.HealthcareCostDoc && <><button type="button" className='view-file' title={appData.HealthcareCostDoc} onClick={() => showFile('HealthcareCostDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.HealthcareCostDoc} onClick={() => downloadFile('HealthcareCostDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>If the Company used a COVID dedicated payroll pay code, please provide a summary of the employees and the hours charged to date.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.SummaryAndHoursCharged}}/></td>
                        <td>{appData.SummaryAndHoursChargedDoc && <><button type="button" className='view-file' title={appData.SummaryAndHoursChargedDoc} onClick={() => showFile('SummaryAndHoursChargedDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.SummaryAndHoursChargedDoc} onClick={() => downloadFile('SummaryAndHoursChargedDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>For each relevant location, please describe any employee assistance programs or additional support efforts provided by the Company to employees displaced and/or otherwise affected by COVID-19.</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.AssistanceProgramSupport}}/></td>
                        <td>{appData.AssistanceProgramSupportDoc && <><button type="button" className='view-file' title={appData.AssistanceProgramSupportDoc} onClick={() => showFile('AssistanceProgramSupportDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.AssistanceProgramSupportDoc} onClick={() => downloadFile('AssistanceProgramSupportDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                      <tr>
                        <td>Did the Bank pay employee for more hours than there was work due to idle time?</td>
                        <td><span dangerouslySetInnerHTML={{__html: appData.EmployeePaidMoreHours}}/></td>
                        <td>{appData.EmployeePaidMoreHoursDoc && <><button type="button" className='view-file' title={appData.EmployeePaidMoreHoursDoc} onClick={() => showFile('EmployeePaidMoreHoursDoc')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.EmployeePaidMoreHoursDoc} onClick={() => downloadFile('EmployeePaidMoreHoursDoc')}><i className="fas fa-file-download"></i></button></>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Container>
            </section> : <section className='bg-gray'>
              <Container fluid>
                <h6>Covid-19 Information</h6>
                <div className='mb-2'>
                  <span className='label'>Periods of disruption:</span>
                  <div className='sub-group'>
                    {disruptionData && disruptionData.sort( (a,b) => b.timestamp - a.timestamp ).map((item,index) => {
                      let quarters = item.DisruptionQuarters;
                      return (<div className="border-top mt-2 pt-2" key={index}>
                        <fieldset>
                          <p className='mb-1'><b>Periods of disruption {index + 1}</b></p>
                          {/* {index},{item.id},{item.timestamp},{item.DisruptionState},{item.DisruptionStartDate},{item.DisruptionEndDate},{item.DisruptionDetails},{item.DisruptionComments},{item.DisruptionQuarters},{quarters} */}
                          <Row key={index}>
                            <Col xs={2}>
                              <span className='label'>State</span><br/>{item.DisruptionState}
                            </Col>
                            <Col xs={2}>
                              <span className='label'>Start Date</span><br/>{item.DisruptionStartDate}
                            </Col>
                            <Col xs={2}>
                              <span className='label'>End Date</span><br/>{item.DisruptionEndDate}
                            </Col>
                            <Col xs={2}>
                              <span className='label'>At least a 10% impact?</span><br/>{item.DisruptionImpactAtleastTenPercent}
                            </Col>
                            <Col xs={4}><span className='label'>Quarters affected:</span><br/>{quarters && quarters.map((q,i) => <>{i+1 === quarters.length ? q : <>{q}, </>}</>)}</Col>
                          </Row>
                          {item.DisruptionReducedServiceHoursReasons && <div className="mt-2 mb-3 p-2" style={{backgroundColor: "#fafafa"}}>
                            <span className='label'>Causes:</span>
                            <ul className='columns-3'>{item.DisruptionReducedServiceHoursReasons.map((reason,i) => 
                              <li className="mb-0" key={i}>{reason}{reason.includes("Other") && <>: <span dangerouslySetInnerHTML={{__html: item.DisruptionOtherCircumstances}}/></>}</li>)}
                            </ul>
                          </div>}
                          {item.DisruptionComments && <Row>
                            <Col>
                              <span className='label'>Explanation of how the business was impacted during the period of disruption identified and, where possible, list the specific COVID-related governmental orders that caused the impact you checked above (i.e. State Executive Order 1,  State Executive Order 2, etc.)”</span>
                              <div className='border p-2 bg-white'><span dangerouslySetInnerHTML={{__html: item.DisruptionComments}}/></div>
                            </Col>
                          </Row>}
                        </fieldset>
                      </div>)
                    })}
                  </div>

                  <table className="table table-striped table-borderless mb-0 mt-5 border">
                    <thead>
                      <tr>
                        <th>Quarters</th>
                        <th>Type of disruption</th>
                        <th>Starting Date</th>
                        <th>Ending Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appData.Details2020Q1 && <tr>
                        <th>2020 Q1</th>
                        <td>{appData.Details2020Q1}</td>
                        <td>{appData.StartDate2020Q1}</td>
                        <td>{appData.EndDate2020Q1}</td>
                      </tr>}
                      {appData.Details2020Q2 && <tr>
                        <th>2020 Q2</th>
                        <td>{appData.Details2020Q2}</td>
                        <td>{appData.StartDate2020Q2}</td>
                        <td>{appData.EndDate2020Q2}</td>
                      </tr>}
                      {appData.Details2020Q3 && <tr>
                        <th>2020 Q3</th>
                        <td>{appData.Details2020Q3}</td>
                        <td>{appData.StartDate2020Q3}</td>
                        <td>{appData.EndDate2020Q3}</td>
                      </tr>}
                      {appData.Details2020Q4 && <tr>
                        <th>2020 Q4</th>
                        <td>{appData.Details2020Q4}</td>
                        <td>{appData.StartDate2020Q4}</td>
                        <td>{appData.EndDate2020Q4}</td>
                      </tr>}
                      {appData.Details2021Q1 && <tr>
                        <th>2021 Q1</th>
                        <td>{appData.Details2021Q1}</td>
                        <td>{appData.StartDate2021Q1}</td>
                        <td>{appData.EndDate2021Q1}</td>
                      </tr>}
                      {appData.Details2021Q2 && <tr>
                        <th>2021 Q2</th>
                        <td>{appData.Details2021Q2}</td>
                        <td>{appData.StartDate2021Q2}</td>
                        <td>{appData.EndDate2021Q2}</td>
                      </tr>}
                      {appData.Details2021Q3 && <tr>
                        <th>2021 Q3</th>
                        <td>{appData.Details2021Q3}</td>
                        <td>{appData.StartDate2021Q3}</td>
                        <td>{appData.EndDate2021Q3}</td>
                      </tr>}
                      {appData.Details2021Q4 && <tr>
                        <th>2021 Q4</th>
                        <td>{appData.Details2021Q4}</td>
                        <td>{appData.StartDate2021Q4}</td>
                        <td>{appData.EndDate2021Q4}</td>
                      </tr>}
                    </tbody>
                  </table>
                  {/* <div className='bg-white mt-3'> */}
                  <div className='mt-3'>
                    {/* <ul className='quarters-list'>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2019 Q1") > -1 ? 'impacted' : null}>2019 Q1</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2019 Q2") > -1 ? 'impacted' : null}>2019 Q2</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2019 Q3") > -1 ? 'impacted' : null}>2019 Q3</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2019 Q4") > -1 ? 'impacted' : null}>2019 Q4</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2020 Q1") > -1 ? 'impacted' : null}>2020 Q1</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2020 Q2") > -1 ? 'impacted' : null}>2020 Q2</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2020 Q3") > -1 ? 'impacted' : null}>2020 Q3</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2020 Q4") > -1 ? 'impacted' : null}>2020 Q4</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2021 Q1") > -1 ? 'impacted' : null}>2021 Q1</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2021 Q2") > -1 ? 'impacted' : null}>2021 Q2</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2021 Q3") > -1 ? 'impacted' : null}>2021 Q3</li>
                      <li className={dataCovidImpactedQuarters && dataCovidImpactedQuarters.indexOf("2021 Q4") > -1 ? 'impacted' : null}>2021 Q4</li>
                    </ul> */}
                  </div>
                </div>
                <div className='mt-4 mb-2'>
                  <span className='label'>Causes:</span>
                  <ul className='mt-2 columns-2'>
                    {dataReducedServiceHoursReasons && dataReducedServiceHoursReasons.map((items,index) => <li key={index}>{items}</li>)}
                  </ul>
                </div>
                <div>
                  {appData.OtherCircumstances && 
                    <p><span className='label'>Other circumstances:</span> <span dangerouslySetInnerHTML={{__html: appData.OtherCircumstances}}/></p>
                  }
                  {appData.UnderstandDefinitions && <p>User has read and understands the provided definitions of "governmental orders" and "nominal impact"</p>}
                  {appData.ProvidedCovid19Disruptions && <p>User has analyzed and provided business's COVID-19-related disruptions on a quarterly basis (at a minimum)</p>}
                </div>
              </Container>
            </section>}
            {/* Documents */}
            <section className='bg-gray'>
              <Container fluid>
                <h6>Documents</h6>
                {/* Gross Receipts */}
                {appData.Industry === 'Banks' ? <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Gross Receipts (Banks)</b></div>
                  <div className="sub-group border-bottom mb-3">
                    <div className='mb-2'>
                      <span className='label'>Do you expect to qualify based on Gross Receipts?</span> {appData.QualifyBasedOnGR}
                    </div>
                    {appData.QualifyBasedOnGR !== 'No' && <table className='table table-docs table-borderless mb-4'>
                      <thead>
                        <tr>
                          <th>2019 Q1</th>
                          <th>2019 Q2</th>
                          <th>2019 Q3</th>
                          <th>2019 Q4</th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>{appData.GrossReceiptBanks2019Q1 && <>$ {appData.GrossReceiptBanks2019Q1}<br/></>}
                            {appData.GrossReceiptBanks2019Q1File ? <><button className='view-file' title={appData.GrossReceiptBanks2019Q1File} onClick={() => showFile('GrossReceiptBanks2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2019Q1File} onClick={() => downloadFile('GrossReceiptBanks2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2019Q2 && <>$ {appData.GrossReceiptBanks2019Q2}<br/></>}
                            {appData.GrossReceiptBanks2019Q2File ? <><button className='view-file' title={appData.GrossReceiptBanks2019Q2File} onClick={() => showFile('GrossReceiptBanks2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2019Q2File} onClick={() => downloadFile('GrossReceiptBanks2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2019Q3 && <>$ {appData.GrossReceiptBanks2019Q3}<br/></>}
                            {appData.GrossReceiptBanks2019Q3File ? <><button className='view-file' title={appData.GrossReceiptBanks2019Q3File} onClick={() => showFile('GrossReceiptBanks2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2019Q3File} onClick={() => downloadFile('GrossReceiptBanks2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2019Q4 && <>$ {appData.GrossReceiptBanks2019Q4}<br/></>}
                            {appData.GrossReceiptBanks2019Q4File ? <><button className='view-file' title={appData.GrossReceiptBanks2019Q4File} onClick={() => showFile('GrossReceiptBanks2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2019Q4File} onClick={() => downloadFile('GrossReceiptBanks2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2020Q1 && <>$ {appData.GrossReceiptBanks2020Q1}<br/></>}
                            {appData.GrossReceiptBanks2020Q1File ? <><button className='view-file' title={appData.GrossReceiptBanks2020Q1File} onClick={() => showFile('GrossReceiptBanks2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2020Q1File} onClick={() => downloadFile('GrossReceiptBanks2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2020Q2 && <>$ {appData.GrossReceiptBanks2020Q2}<br/></>}
                            {appData.GrossReceiptBanks2020Q2File ? <><button className='view-file' title={appData.GrossReceiptBanks2020Q2File} onClick={() => showFile('GrossReceiptBanks2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2020Q2File} onClick={() => downloadFile('GrossReceiptBanks2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2020Q3 && <>$ {appData.GrossReceiptBanks2020Q3}<br/></>}
                            {appData.GrossReceiptBanks2020Q3File ? <><button className='view-file' title={appData.GrossReceiptBanks2020Q3File} onClick={() => showFile('GrossReceiptBanks2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2020Q3File} onClick={() => downloadFile('GrossReceiptBanks2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2020Q4 && <>$ {appData.GrossReceiptBanks2020Q4}<br/></>}
                            {appData.GrossReceiptBanks2020Q4File ? <><button className='view-file' title={appData.GrossReceiptBanks2020Q4File} onClick={() => showFile('GrossReceiptBanks2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2020Q4File} onClick={() => downloadFile('GrossReceiptBanks2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2021Q1 && <>$ {appData.GrossReceiptBanks2021Q1}<br/></>}
                            {appData.GrossReceiptBanks2021Q1File ? <><button className='view-file' title={appData.GrossReceiptBanks2021Q1File} onClick={() => showFile('GrossReceiptBanks2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2021Q1File} onClick={() => downloadFile('GrossReceiptBanks2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2021Q2 && <>$ {appData.GrossReceiptBanks2021Q2}<br/></>}
                            {appData.GrossReceiptBanks2021Q2File ? <><button className='view-file' title={appData.GrossReceiptBanks2021Q2File} onClick={() => showFile('GrossReceiptBanks2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2021Q2File} onClick={() => downloadFile('GrossReceiptBanks2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2021Q3 && <>$ {appData.GrossReceiptBanks2021Q3}<br/></>}
                            {appData.GrossReceiptBanks2021Q3File ? <><button className='view-file' title={appData.GrossReceiptBanks2021Q3File} onClick={() => showFile('GrossReceiptBanks2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2021Q3File} onClick={() => downloadFile('GrossReceiptBanks2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceiptBanks2021Q4 && <>$ {appData.GrossReceiptBanks2021Q4}<br/></>}
                            {appData.GrossReceiptBanks2021Q4File ? <><button className='view-file' title={appData.GrossReceiptBanks2021Q4File} onClick={() => showFile('GrossReceiptBanks2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceiptBanks2021Q4File} onClick={() => downloadFile('GrossReceiptBanks2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>}
                    <div>
                      {appData.ProvidedGRAsPerDefinitionBanks && <p>Provided gross receipts per the definition provided.</p>}
                      {appData.ProvidedGRAsPerAggregatedGroupBanks && <p>Provided gross receipts for business and all affiliated entities identified (as applicable) per the definition of aggregated group provided.</p>}
                    </div>
                  </div>
                </> : <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Gross Receipts</b></div>
                  <div className="sub-group border-bottom mb-3">
                    <table className='table table-docs table-borderless mt-2 mb-4'>
                      <thead>
                        <tr>
                          <th>2019 Q1</th>
                          <th>2019 Q2</th>
                          <th>2019 Q3</th>
                          <th>2019 Q4</th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                        <td>{appData.GrossReceipt2019Q1 && <>$ {appData.GrossReceipt2019Q1}<br/></>}
                            {appData.GrossReceipt2019Q1File ? <><button className='view-file' title={appData.GrossReceipt2019Q1File} onClick={() => showFile('GrossReceipt2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2019Q1File} onClick={() => downloadFile('GrossReceipt2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2019Q2 && <>$ {appData.GrossReceipt2019Q2}<br/></>}
                            {appData.GrossReceipt2019Q2File ? <><button className='view-file' title={appData.GrossReceipt2019Q2File} onClick={() => showFile('GrossReceipt2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2019Q2File} onClick={() => downloadFile('GrossReceipt2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2019Q3 && <>$ {appData.GrossReceipt2019Q3}<br/></>}
                            {appData.GrossReceipt2019Q3File ? <><button className='view-file' title={appData.GrossReceipt2019Q3File} onClick={() => showFile('GrossReceipt2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2019Q3File} onClick={() => downloadFile('GrossReceipt2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2019Q4 && <>$ {appData.GrossReceipt2019Q4}<br/></>}
                            {appData.GrossReceipt2019Q4File ? <><button className='view-file' title={appData.GrossReceipt2019Q4File} onClick={() => showFile('GrossReceipt2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2019Q4File} onClick={() => downloadFile('GrossReceipt2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2020Q1 && <>$ {appData.GrossReceipt2020Q1}<br/></>}
                            {appData.GrossReceipt2020Q1File ? <><button className='view-file' title={appData.GrossReceipt2020Q1File} onClick={() => showFile('GrossReceipt2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2020Q1File} onClick={() => downloadFile('GrossReceipt2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2020Q2 && <>$ {appData.GrossReceipt2020Q2}<br/></>}
                            {appData.GrossReceipt2020Q2File ? <><button className='view-file' title={appData.GrossReceipt2020Q2File} onClick={() => showFile('GrossReceipt2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2020Q2File} onClick={() => downloadFile('GrossReceipt2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2020Q3 && <>$ {appData.GrossReceipt2020Q3}<br/></>}
                            {appData.GrossReceipt2020Q3File ? <><button className='view-file' title={appData.GrossReceipt2020Q3File} onClick={() => showFile('GrossReceipt2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2020Q3File} onClick={() => downloadFile('GrossReceipt2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2020Q4 && <>$ {appData.GrossReceipt2020Q4}<br/></>}
                            {appData.GrossReceipt2020Q4File ? <><button className='view-file' title={appData.GrossReceipt2020Q4File} onClick={() => showFile('GrossReceipt2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2020Q4File} onClick={() => downloadFile('GrossReceipt2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2021Q1 && <>$ {appData.GrossReceipt2021Q1}<br/></>}
                            {appData.GrossReceipt2021Q1File ? <><button className='view-file' title={appData.GrossReceipt2021Q1File} onClick={() => showFile('GrossReceipt2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2021Q1File} onClick={() => downloadFile('GrossReceipt2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2021Q2 && <>$ {appData.GrossReceipt2021Q2}<br/></>}
                            {appData.GrossReceipt2021Q2File ? <><button className='view-file' title={appData.GrossReceipt2021Q2File} onClick={() => showFile('GrossReceipt2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2021Q2File} onClick={() => downloadFile('GrossReceipt2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2021Q3 && <>$ {appData.GrossReceipt2021Q3}<br/></>}
                            {appData.GrossReceipt2021Q3File ? <><button className='view-file' title={appData.GrossReceipt2021Q3File} onClick={() => showFile('GrossReceipt2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2021Q3File} onClick={() => downloadFile('GrossReceipt2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                          <td>{appData.GrossReceipt2021Q4 && <>$ {appData.GrossReceipt2021Q4}<br/></>}
                            {appData.GrossReceipt2021Q4File ? <><button className='view-file' title={appData.GrossReceipt2021Q4File} onClick={() => showFile('GrossReceipt2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.GrossReceipt2021Q4File} onClick={() => downloadFile('GrossReceipt2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      {appData.ProvidedGRAsPerDefinition && <p>Provided gross receipts per the definition provided.</p>}
                      {appData.ProvidedGRAsPerAggregatedGroup && <p>Provided gross receipts for business and all affiliated entities identified (as applicable) per the definition of aggregated group provided.</p>}
                    </div>
                  </div>
                </>}
                
                {/* Expenses */}
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Expenses</b></div>
                <div className="sub-group border-bottom mb-3">
                  <table className='table table-docs table-borderless mt-2 mb-4'>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>2020 Q1</th>
                        <th>2020 Q2</th>
                        <th>2020 Q3</th>
                        <th>2020 Q4</th>
                        <th>2021 Q1</th>
                        <th>2021 Q2</th>
                        <th>2021 Q3</th>
                        <th>2021 Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan={4} style={{textAlign:"right"}}>Health Insurance</th>
                        <td>{appData.ExpensesHealthInsurance2020Q1 ? <>$ {appData.ExpensesHealthInsurance2020Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2020Q2 ? <>$ {appData.ExpensesHealthInsurance2020Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2020Q3 ? <>$ {appData.ExpensesHealthInsurance2020Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2020Q4 ? <>$ {appData.ExpensesHealthInsurance2020Q4}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2021Q1 ? <>$ {appData.ExpensesHealthInsurance2021Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2021Q2 ? <>$ {appData.ExpensesHealthInsurance2021Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2021Q3 ? <>$ {appData.ExpensesHealthInsurance2021Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesHealthInsurance2021Q4 ? <>$ {appData.ExpensesHealthInsurance2021Q4}</> : <>&nbsp;</>}</td>
                      </tr>
                      <tr>
                        <th colSpan={4} style={{textAlign:"right"}}>Rent</th>
                        <td>{appData.ExpensesRent2020Q1 ? <>$ {appData.ExpensesRent2020Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2020Q2 ? <>$ {appData.ExpensesRent2020Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2020Q3 ? <>$ {appData.ExpensesRent2020Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2020Q4 ? <>$ {appData.ExpensesRent2020Q4}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2021Q1 ? <>$ {appData.ExpensesRent2021Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2021Q2 ? <>$ {appData.ExpensesRent2021Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2021Q3 ? <>$ {appData.ExpensesRent2021Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesRent2021Q4 ? <>$ {appData.ExpensesRent2021Q4}</> : <>&nbsp;</>}</td>
                      </tr>
                      <tr>
                        <th colSpan={4} style={{textAlign:"right"}}>Utilities</th>
                        <td>{appData.ExpensesUtilities2020Q1 ? <>$ {appData.ExpensesUtilities2020Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2020Q2 ? <>$ {appData.ExpensesUtilities2020Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2020Q3 ? <>$ {appData.ExpensesUtilities2020Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2020Q4 ? <>$ {appData.ExpensesUtilities2020Q4}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2021Q1 ? <>$ {appData.ExpensesUtilities2021Q1}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2021Q2 ? <>$ {appData.ExpensesUtilities2021Q2}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2021Q3 ? <>$ {appData.ExpensesUtilities2021Q3}</> : <>&nbsp;</>}</td>
                        <td>{appData.ExpensesUtilities2021Q4 ? <>$ {appData.ExpensesUtilities2021Q4}</> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Form 941 */}
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Form 941</b></div>
                <div className="sub-group border-bottom mb-3">
                  <table className='table table-docs table-borderless mt-2 mb-4'>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>2020 Q1</th>
                        <th>2020 Q2</th>
                        <th>2020 Q3</th>
                        <th>2020 Q4</th>
                        <th>2021 Q1</th>
                        <th>2021 Q2</th>
                        <th>2021 Q3</th>
                        <th>2021 Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{appData.Form9412020Q1File ? <><button className='view-file' title={appData.Form9412020Q1File} onClick={() => showFile('Form9412020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412020Q1File} onClick={() => downloadFile('Form9412020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412020Q2File ? <><button className='view-file' title={appData.Form9412020Q2File} onClick={() => showFile('Form9412020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412020Q2File} onClick={() => downloadFile('Form9412020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412020Q3File ? <><button className='view-file' title={appData.Form9412020Q3File} onClick={() => showFile('Form9412020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412020Q3File} onClick={() => downloadFile('Form9412020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412020Q4File ? <><button className='view-file' title={appData.Form9412020Q4File} onClick={() => showFile('Form9412020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412020Q4File} onClick={() => downloadFile('Form9412020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412021Q1File ? <><button className='view-file' title={appData.Form9412021Q1File} onClick={() => showFile('Form9412021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412021Q1File} onClick={() => downloadFile('Form9412021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412021Q2File ? <><button className='view-file' title={appData.Form9412021Q2File} onClick={() => showFile('Form9412021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412021Q2File} onClick={() => downloadFile('Form9412021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412021Q3File ? <><button className='view-file' title={appData.Form9412021Q3File} onClick={() => showFile('Form9412021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412021Q3File} onClick={() => downloadFile('Form9412021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form9412021Q4File ? <><button className='view-file' title={appData.Form9412021Q4File} onClick={() => showFile('Form9412021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form9412021Q4File} onClick={() => downloadFile('Form9412021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Form 941-X */}
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Form 941-X</b></div>
                <div className="sub-group border-bottom mb-3">
                  <table className='table table-docs table-borderless mt-2 mb-4'>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>2020 Q1</th>
                        <th>2020 Q2</th>
                        <th>2020 Q3</th>
                        <th>2020 Q4</th>
                        <th>2021 Q1</th>
                        <th>2021 Q2</th>
                        <th>2021 Q3</th>
                        <th>2021 Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{appData.Form941x2020Q1File ? <><button className='view-file' title={appData.Form941x2020Q1File} onClick={() => showFile('Form941x2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2020Q1File} onClick={() => downloadFile('Form941x2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2020Q2File ? <><button className='view-file' title={appData.Form941x2020Q2File} onClick={() => showFile('Form941x2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2020Q2File} onClick={() => downloadFile('Form941x2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2020Q3File ? <><button className='view-file' title={appData.Form941x2020Q3File} onClick={() => showFile('Form941x2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2020Q3File} onClick={() => downloadFile('Form941x2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2020Q4File ? <><button className='view-file' title={appData.Form941x2020Q4File} onClick={() => showFile('Form941x2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2020Q4File} onClick={() => downloadFile('Form941x2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2021Q1File ? <><button className='view-file' title={appData.Form941x2021Q1File} onClick={() => showFile('Form941x2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2021Q1File} onClick={() => downloadFile('Form941x2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2021Q2File ? <><button className='view-file' title={appData.Form941x2021Q2File} onClick={() => showFile('Form941x2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2021Q2File} onClick={() => downloadFile('Form941x2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2021Q3File ? <><button className='view-file' title={appData.Form941x2021Q3File} onClick={() => showFile('Form941x2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2021Q3File} onClick={() => downloadFile('Form941x2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form941x2021Q4File ? <><button className='view-file' title={appData.Form941x2021Q4File} onClick={() => showFile('Form941x2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form941x2021Q4File} onClick={() => downloadFile('Form941x2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* Payroll */}
                <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Payroll</b></div>
                <div className="sub-group">
                  <div>
                    <span className='label'>Who is the Payroll Provider?</span> {appData.PayrollProvider}{appData.PayrollProviderOther && <>, <span dangerouslySetInnerHTML={{__html: appData.PayrollProviderOther}}/></>}
                  </div>
                </div>
                <div className="sub-group border-bottom pb-3 mb-3">
                  <Row className='mb-3'>
                    <Col><span className='label'>Provided monthly healthcare costs by employee (as applicable), including the share paid by the company and the pre-tax share paid by the employee.</span><br/>{appData.IncludedEmployerPaidHealthInsurance}</Col>
                    <Col><span className='label'>Provided Medicare Wages by employee by pay period</span><br/>{appData.IncludedMedicareTax}</Col>
                  </Row>
                  <div><b>Other Payroll Documents</b></div>
                  {appData.OtherPayrollDoc01 ? <Row>
                    {['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50'].map((items, index) => {
                      return(
                        <>{appData['OtherPayrollDoc'+items] && <Col sm={3} lg={2} className='my-3 text-center'>
                          <div key={index}><button className='view-file bg-transparent' title={appData['OtherPayrollDoc'+items]} onClick={() => showFile('OtherPayrollDoc'+items)}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file bg-transparent' title={appData['OtherPayrollDoc'+items]} onClick={() => downloadFile('OtherPayrollDoc'+items)}><i className="fas fa-file-download"></i></button></div>
                          <div className='txt-overflow' title={appData['OtherPayrollDoc'+items]}>{appData['OtherPayrollDoc'+items]}</div>
                          {appDataTimestamp['OtherPayrollDoc'+items] && <div className='txt-overflow'><small>{appDataTimestamp['OtherPayrollDoc'+items]}</small></div>}
                          </Col>
                        }</>
                      )
                    })}
                  </Row> : <>Null</>}
                  {userPrivilegedReadPayroll && <table className='table table-docs table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>2020 Q1</th>
                        <th>2020 Q2</th>
                        <th>2020 Q3</th>
                        <th>2020 Q4</th>
                        <th>2021 Q1</th>
                        <th>2021 Q2</th>
                        <th>2021 Q3</th>
                        <th>2021 Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{appData.Payroll2019Q1File ? <><button className='view-file' title={appData.Payroll2019Q1File} onClick={() => showFile('Payroll2019Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2019Q1File} onClick={() => downloadFile('Payroll2019Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2019Q2File ? <><button className='view-file' title={appData.Payroll2019Q2File} onClick={() => showFile('Payroll2019Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2019Q2File} onClick={() => downloadFile('Payroll2019Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2019Q3File ? <><button className='view-file' title={appData.Payroll2019Q3File} onClick={() => showFile('Payroll2019Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2019Q3File} onClick={() => downloadFile('Payroll2019Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2019Q4File ? <><button className='view-file' title={appData.Payroll2019Q4File} onClick={() => showFile('Payroll2019Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2019Q4File} onClick={() => downloadFile('Payroll2019Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2020Q1File ? <><button className='view-file' title={appData.Payroll2020Q1File} onClick={() => showFile('Payroll2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2020Q1File} onClick={() => downloadFile('Payroll2020Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2020Q2File ? <><button className='view-file' title={appData.Payroll2020Q2File} onClick={() => showFile('Payroll2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2020Q2File} onClick={() => downloadFile('Payroll2020Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2020Q3File ? <><button className='view-file' title={appData.Payroll2020Q3File} onClick={() => showFile('Payroll2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2020Q3File} onClick={() => downloadFile('Payroll2020Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2020Q4File ? <><button className='view-file' title={appData.Payroll2020Q4File} onClick={() => showFile('Payroll2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2020Q4File} onClick={() => downloadFile('Payroll2020Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2021Q1File ? <><button className='view-file' title={appData.Payroll2021Q1File} onClick={() => showFile('Payroll2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2021Q1File} onClick={() => downloadFile('Payroll2021Q1File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2021Q2File ? <><button className='view-file' title={appData.Payroll2021Q2File} onClick={() => showFile('Payroll2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2021Q2File} onClick={() => downloadFile('Payroll2021Q2File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2021Q3File ? <><button className='view-file' title={appData.Payroll2021Q3File} onClick={() => showFile('Payroll2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2021Q3File} onClick={() => downloadFile('Payroll2021Q3File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Payroll2021Q4File ? <><button className='view-file' title={appData.Payroll2021Q4File} onClick={() => showFile('Payroll2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Payroll2021Q4File} onClick={() => downloadFile('Payroll2021Q4File')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>}
                </div>

                {/* Pseudonymized Payroll */}
                {userPrivilegedReadPseudonymizedPayroll && <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Pseudonymized Payroll</b></div>
                  <div className="sub-group border-bottom pb-3 mb-3">
                    <table className='table table-docs table-upload table-borderless mt-2'>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2020Q1File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2020Q1File" name="PseudonymizedPayroll2020Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2020Q1File && appData.PseudonymizedPayroll2020Q1File}<br/>
                            {appData.PseudonymizedPayroll2020Q1File && <><button className='view-file' title={appData.PseudonymizedPayroll2020Q1File} onClick={() => showFile('PseudonymizedPayroll2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2020Q1File} onClick={() => downloadFile('PseudonymizedPayroll2020Q1File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2020Q2File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2020Q2File" name="PseudonymizedPayroll2020Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2020Q2File && appData.PseudonymizedPayroll2020Q2File}<br/>
                            {appData.PseudonymizedPayroll2020Q2File && <><button className='view-file' title={appData.PseudonymizedPayroll2020Q2File} onClick={() => showFile('PseudonymizedPayroll2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2020Q2File} onClick={() => downloadFile('PseudonymizedPayroll2020Q2File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2020Q3File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2020Q3File" name="PseudonymizedPayroll2020Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2020Q3File && appData.PseudonymizedPayroll2020Q3File}<br/>
                            {appData.PseudonymizedPayroll2020Q3File && <><button className='view-file' title={appData.PseudonymizedPayroll2020Q3File} onClick={() => showFile('PseudonymizedPayroll2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2020Q3File} onClick={() => downloadFile('PseudonymizedPayroll2020Q3File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2020Q4File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2020Q4File" name="PseudonymizedPayroll2020Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2020Q4File && appData.PseudonymizedPayroll2020Q4File}<br/>
                            {appData.PseudonymizedPayroll2020Q4File && <><button className='view-file' title={appData.PseudonymizedPayroll2020Q4File} onClick={() => showFile('PseudonymizedPayroll2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2020Q4File} onClick={() => downloadFile('PseudonymizedPayroll2020Q4File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2021Q1File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2021Q1File" name="PseudonymizedPayroll2021Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2021Q1File && appData.PseudonymizedPayroll2021Q1File}<br/>
                            {appData.PseudonymizedPayroll2021Q1File && <><button className='view-file' title={appData.PseudonymizedPayroll2021Q1File} onClick={() => showFile('PseudonymizedPayroll2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2021Q1File} onClick={() => downloadFile('PseudonymizedPayroll2021Q1File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2021Q2File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2021Q2File" name="PseudonymizedPayroll2021Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2021Q2File && appData.PseudonymizedPayroll2021Q2File}<br/>
                            {appData.PseudonymizedPayroll2021Q2File && <><button className='view-file' title={appData.PseudonymizedPayroll2021Q2File} onClick={() => showFile('PseudonymizedPayroll2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2021Q2File} onClick={() => downloadFile('PseudonymizedPayroll2021Q2File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2021Q3File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2021Q3File" name="PseudonymizedPayroll2021Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2021Q3File && appData.PseudonymizedPayroll2021Q3File}<br/>
                            {appData.PseudonymizedPayroll2021Q3File && <><button className='view-file' title={appData.PseudonymizedPayroll2021Q3File} onClick={() => showFile('PseudonymizedPayroll2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2021Q3File} onClick={() => downloadFile('PseudonymizedPayroll2021Q3File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="PseudonymizedPayroll2021Q4File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="PseudonymizedPayroll2021Q4File" name="PseudonymizedPayroll2021Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWritePseudonymizedPayroll} onChange={(e) => handleUpload('pseudonymized_payroll', e)} />
                            </div>
                            {appData.PseudonymizedPayroll2021Q4File && appData.PseudonymizedPayroll2021Q4File}<br/>
                            {appData.PseudonymizedPayroll2021Q4File && <><button className='view-file' title={appData.PseudonymizedPayroll2021Q4File} onClick={() => showFile('PseudonymizedPayroll2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PseudonymizedPayroll2021Q4File} onClick={() => downloadFile('PseudonymizedPayroll2021Q4File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>}

                {/* Processed Payroll */}
                {userPrivilegedReadProcessedPayroll && <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Processed Payroll</b></div>
                  <div className="sub-group border-bottom pb-3 mb-3">
                    <table className='table table-docs table-upload table-borderless mt-2'>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>2020 Q1</th>
                          <th>2020 Q2</th>
                          <th>2020 Q3</th>
                          <th>2020 Q4</th>
                          <th>2021 Q1</th>
                          <th>2021 Q2</th>
                          <th>2021 Q3</th>
                          <th>2021 Q4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2020Q1File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2020Q1File" name="ProcessedPayroll2020Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2020Q1File && appData.ProcessedPayroll2020Q1File}<br/>
                            {appData.ProcessedPayroll2020Q1File && <><button className='view-file' title={appData.ProcessedPayroll2020Q1File} onClick={() => showFile('ProcessedPayroll2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2020Q1File} onClick={() => downloadFile('ProcessedPayroll2020Q1File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2020Q2File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2020Q2File" name="ProcessedPayroll2020Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2020Q2File && appData.ProcessedPayroll2020Q2File}<br/>
                            {appData.ProcessedPayroll2020Q2File && <><button className='view-file' title={appData.ProcessedPayroll2020Q2File} onClick={() => showFile('ProcessedPayroll2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2020Q2File} onClick={() => downloadFile('ProcessedPayroll2020Q2File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2020Q3File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2020Q3File" name="ProcessedPayroll2020Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2020Q3File && appData.ProcessedPayroll2020Q3File}<br/>
                            {appData.ProcessedPayroll2020Q3File && <><button className='view-file' title={appData.ProcessedPayroll2020Q3File} onClick={() => showFile('ProcessedPayroll2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2020Q3File} onClick={() => downloadFile('ProcessedPayroll2020Q3File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2020Q4File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2020Q4File" name="ProcessedPayroll2020Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2020Q4File && appData.ProcessedPayroll2020Q4File}<br/>
                            {appData.ProcessedPayroll2020Q4File && <><button className='view-file' title={appData.ProcessedPayroll2020Q4File} onClick={() => showFile('ProcessedPayroll2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2020Q4File} onClick={() => downloadFile('ProcessedPayroll2020Q4File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2021Q1File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2021Q1File" name="ProcessedPayroll2021Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2021Q1File && appData.ProcessedPayroll2021Q1File}<br/>
                            {appData.ProcessedPayroll2021Q1File && <><button className='view-file' title={appData.ProcessedPayroll2021Q1File} onClick={() => showFile('ProcessedPayroll2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2021Q1File} onClick={() => downloadFile('ProcessedPayroll2021Q1File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2021Q2File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2021Q2File" name="ProcessedPayroll2021Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2021Q2File && appData.ProcessedPayroll2021Q2File}<br/>
                            {appData.ProcessedPayroll2021Q2File && <><button className='view-file' title={appData.ProcessedPayroll2021Q2File} onClick={() => showFile('ProcessedPayroll2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2021Q2File} onClick={() => downloadFile('ProcessedPayroll2021Q2File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2021Q3File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2021Q3File" name="ProcessedPayroll2021Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2021Q3File && appData.ProcessedPayroll2021Q3File}<br/>
                            {appData.ProcessedPayroll2021Q3File && <><button className='view-file' title={appData.ProcessedPayroll2021Q3File} onClick={() => showFile('ProcessedPayroll2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2021Q3File} onClick={() => downloadFile('ProcessedPayroll2021Q3File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                          <td>
                            <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="ProcessedPayroll2021Q4File" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="ProcessedPayroll2021Q4File" name="ProcessedPayroll2021Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteProcessedPayroll} onChange={(e) => handleUpload('processed_payroll', e)} />
                            </div>
                            {appData.ProcessedPayroll2021Q4File && appData.ProcessedPayroll2021Q4File}<br/>
                            {appData.ProcessedPayroll2021Q4File && <><button className='view-file' title={appData.ProcessedPayroll2021Q4File} onClick={() => showFile('ProcessedPayroll2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.ProcessedPayroll2021Q4File} onClick={() => downloadFile('ProcessedPayroll2021Q4File')}><i className="fas fa-file-download"></i></button></>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>}

                {/* Documents from Tax Preparer Support */}
                {userPrivilegedReadSupportPayroll && <>
                  <div className='d-flex align-items-center mb-2'><img src={require('./../assets/images/list-icon-arrow.png')} alt="Bullet icon" height="12" className='me-2' /><b>Documents from Tax Preparer Support</b></div>
                  <div className="sub-group">
                    <div className='table-wrapper'>
                      <table className='table table-docs table-upload table-borderless mt-2'>
                        <thead>
                          <tr>
                            <th>Doc 01</th>
                            <th>Doc 02</th>
                            <th>Doc 03</th>
                            <th>Doc 04</th>
                            <th>Doc 05</th>
                            <th>Doc 06</th>
                            <th>Doc 07</th>
                            <th>Doc 08</th>
                            <th>Doc 09</th>
                            <th>Doc 10</th>
                            <th>Doc 11</th>
                            <th>Doc 12</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {Array.apply(null, {length: 12}).map((item, index) => {
                              let count = index +1;
                              return(
                                <td>
                                  {<div className="position-relative d-inline-block">
                                    <Form.Label htmlFor={"TaxPreparerSupportDoc"+count} className='upload upload-default'>
                                      <span className="txt-upload">Upload</span>
                                    </Form.Label>
                                    <Form.Control id={"TaxPreparerSupportDoc"+count} name={"TaxPreparerSupportDoc"+count} type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedWriteSupportPayroll} onChange={(e) => handleUpload('documents_from_tp_support', e)} />
                                  </div>}
                                  {appData[`TaxPreparerSupportDoc${count}`] && appData[`TaxPreparerSupportDoc${count}`]}<br/>
                                  {appData[`TaxPreparerSupportDoc${count}`] && <><button type="button" className='view-file' title={appData[`TaxPreparerSupportDoc${count}`]} onClick={() => showFile(`TaxPreparerSupportDoc${count}`)}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData[`TaxPreparerSupportDoc${count}`]} onClick={() => downloadFile(`TaxPreparerSupportDoc${count}`)}><i className="fas fa-file-download"></i></button></>}
                                </td>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>}
              </Container>
            </section>
            {/* Power of Attorney */}
            <section className='bg-gray'>
              <Container fluid>
                <h6>Power of Attorney</h6>
                <div className="sub-group">
                  <table className='table table-docs table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th>Form2848</th>
                        <th>Form8821</th>
                        <th>Form8822B</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{appData.Form2848 ? <><button className='view-file' title={appData.Form2848} onClick={() => showFile('Form2848')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form2848} onClick={() => downloadFile('Form2848')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form8821 ? <><button className='view-file' title={appData.Form8821} onClick={() => showFile('Form8821')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form8821} onClick={() => downloadFile('Form8821')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.Form8822B ? <><button className='view-file' title={appData.Form8822B} onClick={() => showFile('Form8822B')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.Form8822B} onClick={() => downloadFile('Form8822B')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Container>
            </section>

            {/* Loan Forgiveness forms */}
            <section className='bg-gray'>
              <Container fluid>
                <h6>Loan Forgiveness Forms</h6>
                <div className='mb-3'><span className='label'>Are PPP Loans applicable?</span> {appData.PPPLoansApplicable}</div>
                <div className="sub-group">
                  <ul className='ps-3'>
                    <li>
                      <Row>
                        <Col>PPP 1</Col>
                        <Col><span className='label'>PPP Loan Number</span><br/>{appData.PPP1LoanNumber}</Col>
                        <Col><span className='label'>PPP Covered Period Start Date</span><br/>{appData.PPP1StartDate}</Col>
                        <Col><span className='label'>PPP Covered Period End Date</span><br/>{appData.PPP1EndDate}</Col>
                        <Col><span className='label'>Total loan amount</span><br/>{appData.PPP1Amount}</Col>
                        {/* <Col><span className='label'>Loan amount applied for payroll cost</span><br/>{appData.PPP1Payroll}</Col> */}
                        <Col><span className='label'>Loan amount forgiven</span><br/>{appData.PPP1Forgiven}</Col>
                        <Col><span className='label'>Non Payroll Costs applied</span><br/>{appData.PPP1NonPayrollCosts}</Col>
                        <Col><span className='label'>Payroll Costs applied</span><br/>{appData.PPP1PayrollCosts}</Col>
                      </Row>
                    </li>
                    <li>
                      <Row className='border-top'>
                        <Col>PPP 2</Col>
                        <Col><span className='label'>PPP Loan Number</span><br/>{appData.PPP2LoanNumber}</Col>
                        <Col><span className='label'>PPP Covered Period Start Date</span><br/>{appData.PPP2StartDate}</Col>
                        <Col><span className='label'>PPP Covered Period End Date</span><br/>{appData.PPP2EndDate}</Col>
                        <Col><span className='label'>Total loan amount</span><br/>{appData.PPP2Amount}</Col>
                        {/* <Col><span className='label'>Loan amount applied for payroll cost</span><br/>{appData.PPP2Payroll}</Col> */}
                        <Col><span className='label'>Loan amount forgiven</span><br/>{appData.PPP2Forgiven}</Col>
                        <Col><span className='label'>Non Payroll Costs applied</span><br/>{appData.PPP2NonPayrollCosts}</Col>
                        <Col><span className='label'>Payroll Costs applied</span><br/>{appData.PPP2PayrollCosts}</Col>
                      </Row>
                    </li>
                  </ul>
                  <table className='table table-docs table-borderless mt-3'>
                    <thead>
                      <tr>
                        <th>PPP1</th>
                        <th>PPP2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{appData.PPP1 ? <><button className='view-file' title={appData.PPP1} onClick={() => showFile('PPP1')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPP1} onClick={() => downloadFile('PPP1')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                        <td>{appData.PPP2 ? <><button className='view-file' title={appData.PPP2} onClick={() => showFile('PPP2')}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file' title={appData.PPP2} onClick={() => downloadFile('PPP2')}><i className="fas fa-file-download"></i></button></> : <>&nbsp;</>}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Container>
            </section>

            {/* Additional Documents from Customer */}
            <section className='bg-gray'>
              <Container fluid>
                <h6>Additional Documents from Customer</h6>
                {appData.Doc01 ? <Row>
                  {['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50'].map((items, index) => {
                    return(
                      <>{appData['Doc'+items] && <Col sm={3} lg={2} className='my-3 text-center'>
                        <div key={index}><button className='view-file bg-transparent' title={appData['Doc'+items]} onClick={() => showFile('Doc'+items)}><img src={require('./../assets/images/button-icon.png')} /></button><button className='download-file bg-transparent' title={appData['Doc'+items]} onClick={() => downloadFile('Doc'+items)}><i className="fas fa-file-download"></i></button></div>
                        <div className='txt-overflow' title={appData['Doc'+items]}>{appData['Doc'+items]}</div>
                        {appDataTimestamp['Doc'+items] && <div className='txt-overflow'><small>{appDataTimestamp['Doc'+items]}</small></div>}
                        </Col>
                      }</>
                    )
                  })}
                </Row> : <>Null</>}
              </Container>
            </section>
            {/* Statement of Fact */}
            {userPrivilegedPIIInfo && <section className='bg-gray'>
              <Container fluid>
                <h6>Statement of Fact</h6>
                {(appData.SignedStatementOfFactFile || appData.CdCompanyName) ? <></> : <div>
                  <Form.Check type="radio" inline name="sof-options" id="sof-upload" defaultValue="sof-upload" label="Upload the SoF" onChange={handleSelectSofOption} />
                  <Form.Check type="radio" inline name="sof-options" id="sof-template" defaultValue="sof-template" label="Use the SoF template" onChange={handleSelectSofOption} />
                </div>}
                {(sofOption && sofOption === 'sof-upload') && <>
                  <Row className='mt-2'>
                    <Col>
                      <span className='label'>Statement of Fact file from Tax Preparer</span>
                      <div className='d-flex align-items-center'>
                        {(!appData.StatementOfFactSentToCustomer && !appData.SignedStatementOfFactFile) &&
                        <div className="position-relative d-inline-block">
                          <Form.Label htmlFor="StatementOfFactFile" className='upload upload-default'>
                            <span className="txt-upload">Upload</span>
                          </Form.Label>
                          <Form.Control id="StatementOfFactFile" name="StatementOfFactFile" type="file" accept=".pdf" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('statement_of_fact', e)} />
                        </div>}
                        {appData.StatementOfFactFile && appData.StatementOfFactFile}<br/>
                        {appData.StatementOfFactFile && <><button type="button" className='view-file' title={appData.StatementOfFactFile} onClick={() => showFile('StatementOfFactFile')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.StatementOfFactFile} onClick={() => downloadFile('StatementOfFactFile')}><i className="fas fa-file-download"></i></button></>}
                      </div>
                    </Col>
                    <Col>
                      {(appData.StatementOfFactSentToCustomer || appData.SignedStatementOfFactFile) && <>
                        <span className='label'>Status</span><br />
                        {appData.SignedStatementOfFactFile ? <div className='d-flex align-items-center'>
                          <div>Statement of Fact <b>signed</b> by customer</div>
                          <div className='ms-3'>{appData.SignedStatementOfFactFile}<br/>
                            <button type="button" className='view-file' title={appData.SignedStatementOfFactFile} onClick={() => showFile('SignedStatementOfFactFile')}><img src={require('./../assets/images/button-icon.png')} /></button>
                            <button type="button" className='download-file' title={appData.SignedStatementOfFactFile} onClick={() => downloadFile('SignedStatementOfFactFile')}><i className="fas fa-file-download"></i></button>
                          </div>
                        </div> : (appData.StatementOfFactSentToCustomer && 'Statement of Fact sent to customer')}
                      </>}
                    </Col>
                  </Row>
                  {!appData.StatementOfFactSentToCustomer && <div className='mt-3'><Button variant="primary" type="submit" disabled={(appData.Submitted && appData.StatementOfFactFile) ? false : true} onClick={(e) => handleSetData('statement_of_fact', 'StatementOfFactSentToCustomer', true, e)}>Send to Customer</Button></div>}
                </>}
                {(sofOption && sofOption === 'sof-template') && <>
                  <Row className='mt-2'>
                    <Col>
                      <Button variant="transparent" type="button" disabled={!appData.Submitted} onClick={handleShowSoF}>View Statement of Fact</Button>
                      <Modal show={showSoF} onHide={handleCloseSoF} backdrop="static" keyboard={false} centered className='modal-medium'>
                        <Form onSubmit={handleSaveSoFData} className='form-style-1'>
                          <Modal.Header closeButton>
                            <Modal.Title>Statement of Fact</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div>
                              <div>
                                <div><b>Company Name:</b><span className='ms-2' dangerouslySetInnerHTML={{__html: sofInputs.CdCompanyName}} /></div>
                                <div><b>FEIN(s):</b><span className='ms-2' dangerouslySetInnerHTML={{__html: sofInputs.CdFEINNumber}} /></div>
                                <div><b>Industry:</b><span className='ms-2'>{sofInputs.CdIndustry}</span></div>
                                <div><b>Location:</b><span className='ms-2' dangerouslySetInnerHTML={{__html: sofInputs.CdLocation}} /></div>
                                <div><b>Average no. of employees in 2019:</b><span className='ms-2'>{sofInputs.CdAvgEmp2019}</span></div>
                                <div className='mt-2'><b>PPP loan covered period (if applicable):</b></div>
                                <ul className='mb-2'>
                                  <li><b>PPP1:</b><span className='ms-2'>{sofInputs.CdPPP1Period}</span></li>
                                  <li><b>PPP2:</b><span className='ms-2'>{sofInputs.CdPPP2Period}</span></li>
                                </ul>
                                <div className='mb-2'><b>Names of owner(s) & percentage(s) of ownership:</b><br/><span dangerouslySetInnerHTML={{__html: sofInputs.CdOwnersNameAndPercentageOwnership}} /></div>
                                <div className='mb-2'><b>Name of all employee(s) who are related to any owner and name of the related owner(s). Please mention the relationship between such employee(s) and owner(s).:</b><br/><span dangerouslySetInnerHTML={{__html: sofInputs.CdOwnersRelatedAndRelationship}} /></div>
                                <div className='mb-3'><b>Name of all employee(s) who are related to any owner and name of the related owner:</b><br/><span dangerouslySetInnerHTML={{__html: sofInputs.CdEmployeeNamesRelatedOwnerRelationship}} /></div>
                                <div><b>Client provided gross receipts:</b></div>
                                <Row>
                                  <Col>
                                    <div><b><u>2019</u></b></div>
                                    <div><b>Q1:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2019Q1 && <>${sofInputs.CdGrossReceipt2019Q1}</>}</span></div>
                                    <div><b>Q2:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2019Q2 && <>${sofInputs.CdGrossReceipt2019Q2}</>}</span></div>
                                    <div><b>Q3:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2019Q3 && <>${sofInputs.CdGrossReceipt2019Q3}</>}</span></div>
                                    <div><b>Q4:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2019Q4 && <>${sofInputs.CdGrossReceipt2019Q4}</>}</span></div>
                                  </Col>
                                  <Col>
                                    <div><b><u>2020</u></b></div>
                                    <div><b>Q1:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2020Q1 && <>${sofInputs.CdGrossReceipt2020Q1}</>}</span></div>
                                    <div><b>Q2:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2020Q2 && <>${sofInputs.CdGrossReceipt2020Q2}</>}</span></div>
                                    <div><b>Q3:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2020Q3 && <>${sofInputs.CdGrossReceipt2020Q3}</>}</span></div>
                                    <div><b>Q4:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2020Q4 && <>${sofInputs.CdGrossReceipt2020Q4}</>}</span></div>
                                  </Col>
                                  <Col>
                                    <div><b><u>2021</u></b></div>
                                    <div><b>Q1:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2021Q1 && <>${sofInputs.CdGrossReceipt2021Q1}</>}</span></div>
                                    <div><b>Q2:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2021Q2 && <>${sofInputs.CdGrossReceipt2021Q2}</>}</span></div>
                                    <div><b>Q3:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2021Q3 && <>${sofInputs.CdGrossReceipt2021Q3}</>}</span></div>
                                    <div><b>Q4:</b><span className='ms-2'>{sofInputs.CdGrossReceipt2021Q4 && <>${sofInputs.CdGrossReceipt2021Q4}</>}</span></div>
                                  </Col>
                                </Row>
                                <div className='mt-2 mb-3'><i>*Note-gross receipts above should include sales net of returns and allowances, all amounts received for services, income from investments, interest, dividends, rents, royalties or annuities. Client represents amounts above are complete and accurate.</i></div>
                              </div>
                              <fieldset disabled={appData.StatementOfFactSentToCustomer}>
                                <Row className='d-flex align-items-end mb-3'>
                                  <Col xs="auto"><Form.Label><b>Eligibility timeframe:</b><span className='txt-red'>*</span></Form.Label></Col>
                                  <Col><Form.Control name="TimeframeStartDate" defaultValue={sofInputs.TpEligibilityTimeframe && sofInputs.TpEligibilityTimeframe.split(" to ")[0]} onChange={handleChange} type="date" min="2020-01-01" max="2021-12-31" className='border' required /></Col>
                                  <Col><Form.Control name="TimeframeEndDate" defaultValue={sofInputs.TpEligibilityTimeframe && sofInputs.TpEligibilityTimeframe.split(" to ")[1]} onChange={handleChange} type="date" min={timeframeStartDate || "2020-01-01"} max="2021-12-31" className='border' required /></Col>
                                  <Col><Form.Control name="TpEligibilityTimeframe" defaultValue={sofInputs.TpEligibilityTimeframe} onChange={handleChange} type="hidden" className='border' /></Col>
                                </Row>
                                <div><b>Eligibility notes:</b></div>
                                <Form.Group className='mb-2'>
                                  <Form.Label>Gross receipts:<span className='txt-red'>*</span></Form.Label>
                                  <Form.Control as="textarea" id="TpEligibilityNotesGR" name="TpEligibilityNotesGR" defaultValue={sofInputs.TpEligibilityNotesGR} placeholder='Type here...' onChange={handleChange} style={{ height: '100px' }} className='border' required />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                  <Form.Label>Full or partial suspension due to government orders:<span className='txt-red'>*</span></Form.Label>
                                  <Form.Control as="textarea" id="TpEligibilityNotesSuspension" name="TpEligibilityNotesSuspension" defaultValue={sofInputs.TpEligibilityNotesSuspension} placeholder='Type here...' onChange={handleChange} style={{ height: '100px' }} className='border' required />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                  <Form.Label><b>Employer-provided facts:</b><span className='txt-red'>*</span></Form.Label>
                                  <Form.Control as="textarea" id="TpEmployerProvidedFacts" name="TpEmployerProvidedFacts" defaultValue={sofInputs.TpEmployerProvidedFacts} placeholder='Type here...' onChange={handleChange} style={{ height: '100px' }} className='border' required />
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label><b>Additional comments:</b><span className='txt-red'>*</span></Form.Label>
                                  <Form.Control as="textarea" id="TpAdditionalComments" name="TpAdditionalComments" defaultValue={sofInputs.TpAdditionalComments} placeholder='Type here...' onChange={handleChange} style={{ height: '100px' }} className='border' required />
                                </Form.Group>
                              </fieldset>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="transparent" onClick={handleCloseSoF}>Close</Button>
                            <Button variant="primary" onClick={handleUpdateCustomerDetails} disabled={appData.StatementOfFactSentToCustomer}>Update Customer Details</Button>
                            <Button variant="primary" type="submit" disabled={sofInputs.CdCompanyName === undefined ? true : (appData.StatementOfFactSentToCustomer === 'true' ? true : false)}>Save</Button>
                          </Modal.Footer>
                        </Form>
                      </Modal>
                    </Col>
                    <Col>
                      {(appData.StatementOfFactSentToCustomer || SoFSignedByCustomer.Signed) && <><span className='label'>Status</span><br />{SoFSignedByCustomer.Signed ? <>Statement of Fact accepted by <b>{SoFSignedByCustomer.SignerName}</b> on <b>{SoFSignedByCustomer.SignerDate}</b></> : (appData.StatementOfFactSentToCustomer && 'Statement of Fact sent to customer')}</>}
                    </Col>
                  </Row>
                  {!appData.StatementOfFactSentToCustomer && <div className='mt-3'><Button variant="primary" type="submit" disabled={(appData.Submitted && appData.CdCompanyName && appData.TpEligibilityNotesGR) ? false : true} onClick={(e) => handleSetData('statement_of_fact', 'StatementOfFactSentToCustomer', true, e)}>Send to Customer</Button></div>}
                </>}
              </Container>
            </section>}
            {/* Final Form 941-X*/}
            {userPrivilegedPIIInfo && <section className='bg-gray'>
              <Container fluid>
                <div className='border-bottom pb-3 mb-3'>
                  <h6>Final Form 941-X from Tax Preparer</h6>
                  <table className='table table-docs table-upload table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Tax Preparer Comments</th>
                        <th>2020 Q1</th>
                        <th>2020 Q2</th>
                        <th>2020 Q3</th>
                        <th>2020 Q4</th>
                        <th>2021 Q1</th>
                        <th>2021 Q2</th>
                        <th>2021 Q3</th>
                        <th>2021 Q4</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                              <Form.Label htmlFor="TaxPreparerComments" className='upload upload-default'>
                                <span className="txt-upload">Upload</span>
                              </Form.Label>
                              <Form.Control id="TaxPreparerComments" name="TaxPreparerComments" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                            </div>}
                            {appData.TaxPreparerComments && appData.TaxPreparerComments}<br/>
                            {appData.TaxPreparerComments && <><button type="button" className='view-file' title={appData.TaxPreparerComments} onClick={() => showFile('TaxPreparerComments')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.TaxPreparerComments} onClick={() => downloadFile('TaxPreparerComments')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2020Q1File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2020Q1File" name="FinalForm941x2020Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2020Q1File && appData.FinalForm941x2020Q1File}<br/>
                          {appData.FinalForm941x2020Q1File && <><button type="button" className='view-file' title={appData.FinalForm941x2020Q1File} onClick={() => showFile('FinalForm941x2020Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2020Q1File} onClick={() => downloadFile('FinalForm941x2020Q1File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2020Q2File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2020Q2File" name="FinalForm941x2020Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2020Q2File && appData.FinalForm941x2020Q2File}<br/>
                          {appData.FinalForm941x2020Q2File && <><button type="button" className='view-file' title={appData.FinalForm941x2020Q2File} onClick={() => showFile('FinalForm941x2020Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2020Q2File} onClick={() => downloadFile('FinalForm941x2020Q2File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2020Q3File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2020Q3File" name="FinalForm941x2020Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2020Q3File && appData.FinalForm941x2020Q3File}<br/>
                          {appData.FinalForm941x2020Q3File && <><button type="button" className='view-file' title={appData.FinalForm941x2020Q3File} onClick={() => showFile('FinalForm941x2020Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2020Q3File} onClick={() => downloadFile('FinalForm941x2020Q3File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2020Q4File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2020Q4File" name="FinalForm941x2020Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2020Q4File && appData.FinalForm941x2020Q4File}<br/>
                          {appData.FinalForm941x2020Q4File && <><button type="button" className='view-file' title={appData.FinalForm941x2020Q4File} onClick={() => showFile('FinalForm941x2020Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2020Q4File} onClick={() => downloadFile('FinalForm941x2020Q4File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2021Q1File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2021Q1File" name="FinalForm941x2021Q1File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2021Q1File && appData.FinalForm941x2021Q1File}<br/>
                          {appData.FinalForm941x2021Q1File && <><button type="button" className='view-file' title={appData.FinalForm941x2021Q1File} onClick={() => showFile('FinalForm941x2021Q1File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2021Q1File} onClick={() => downloadFile('FinalForm941x2021Q1File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2021Q2File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2021Q2File" name="FinalForm941x2021Q2File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2021Q2File && appData.FinalForm941x2021Q2File}<br/>
                          {appData.FinalForm941x2021Q2File && <><button type="button" className='view-file' title={appData.FinalForm941x2021Q2File} onClick={() => showFile('FinalForm941x2021Q2File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2021Q2File} onClick={() => downloadFile('FinalForm941x2021Q2File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2021Q3File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2021Q3File" name="FinalForm941x2021Q3File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2021Q3File && appData.FinalForm941x2021Q3File}<br/>
                          {appData.FinalForm941x2021Q3File && <><button type="button" className='view-file' title={appData.FinalForm941x2021Q3File} onClick={() => showFile('FinalForm941x2021Q3File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2021Q3File} onClick={() => downloadFile('FinalForm941x2021Q3File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                        <td>
                          {!appData.FinalForm941xSentToCustomer && <div className="position-relative d-inline-block">
                            <Form.Label htmlFor="FinalForm941x2021Q4File" className='upload upload-default'>
                              <span className="txt-upload">Upload</span>
                            </Form.Label>
                            <Form.Control id="FinalForm941x2021Q4File" name="FinalForm941x2021Q4File" type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                          </div>}
                          {appData.FinalForm941x2021Q4File && appData.FinalForm941x2021Q4File}<br/>
                          {appData.FinalForm941x2021Q4File && <><button type="button" className='view-file' title={appData.FinalForm941x2021Q4File} onClick={() => showFile('FinalForm941x2021Q4File')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.FinalForm941x2021Q4File} onClick={() => downloadFile('FinalForm941x2021Q4File')}><i className="fas fa-file-download"></i></button></>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {!appData.FinalForm941xSentToCustomer && <div className='mt-3'><Button variant="primary" type="submit" disabled={(appData.Submitted && (SoFSignedByCustomer.Signed || appData.SignedStatementOfFactFile) && (appData.FinalForm941x2020Q1File ||appData.FinalForm941x2020Q2File ||appData.FinalForm941x2020Q3File ||appData.FinalForm941x2020Q4File ||appData.FinalForm941x2021Q1File ||appData.FinalForm941x2021Q2File ||appData.FinalForm941x2021Q3File ||appData.FinalForm941x2021Q4File)) ? false : true} onClick={(e) => handleSetData('final_form_941x', 'FinalForm941xSentToCustomer', true, e)}>Send to Customer</Button></div>}
                  {(appData.FinalForm941xSentToCustomer && !appData.SignedFinalForm941x) && <div className='mt-4'><span className='label'>Status:</span> Final Form 941-X sent to customer</div>}
                  {appData.SignedFinalForm941x && <div className='d-flex'>
                    <div className='mt-4'><span className='label'>Status:</span> Final Form 941-X <b>signed</b> by customer</div>
                    <div>
                      {appData.SignedFinalForm941x}<br/>
                      <button type="button" className='view-file' title={appData.SignedFinalForm941x} onClick={() => showFile('SignedFinalForm941x')}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData.SignedFinalForm941x} onClick={() => downloadFile('SignedFinalForm941x')}><i className="fas fa-file-download"></i></button>
                    </div>
                  </div>}
                </div>
                <h6>Additional Documents from Tax Preparer</h6>
                <div className='table-wrapper'>
                  <table className='table table-docs table-upload table-borderless mt-2'>
                    <thead>
                      <tr>
                        <th>Doc 01</th>
                        <th>Doc 02</th>
                        <th>Doc 03</th>
                        <th>Doc 04</th>
                        <th>Doc 05</th>
                        <th>Doc 06</th>
                        <th>Doc 07</th>
                        <th>Doc 08</th>
                        <th>Doc 09</th>
                        <th>Doc 10</th>
                        <th>Doc 11</th>
                        <th>Doc 12</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Array.apply(null, {length: 12}).map((item, index) => {
                          let count = index +1;
                          return(
                            <td>
                              {<div className="position-relative d-inline-block">
                                <Form.Label htmlFor={"FinalForm941xAddDoc"+count} className='upload upload-default'>
                                  <span className="txt-upload">Upload</span>
                                </Form.Label>
                                <Form.Control id={"FinalForm941xAddDoc"+count} name={"FinalForm941xAddDoc"+count} type="file" accept=".xls, .xlsx, .csv, .pdf, .docx" disabled={!userPrivilegedUpdateDocument} onChange={(e) => handleUpload('final_form_941x', e)} />
                              </div>}
                              {appData[`FinalForm941xAddDoc${count}`] && appData[`FinalForm941xAddDoc${count}`]}<br/>
                              {appData[`FinalForm941xAddDoc${count}`] && <><button type="button" className='view-file' title={appData[`FinalForm941xAddDoc${count}`]} onClick={() => showFile(`FinalForm941xAddDoc${count}`)}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title={appData[`FinalForm941xAddDoc${count}`]} onClick={() => downloadFile(`FinalForm941xAddDoc${count}`)}><i className="fas fa-file-download"></i></button></>}
                            </td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Container>
            </section>}

            <div className='mt-4 mb-5'>
              <Container fluid>
                {(appData.TermsOfService && appData.PrivacyPolicy) && <p>* The user agrees to all Terms and Conditions and also the Privacy policy.</p>}
                {(appData.CertificationOfData) && <p>* The user certifies that all the information submitted in the form is correct.</p>}
                <Button variant="transparent" onClick={() => navigate('/')}>Back</Button>
                <Button variant="primary" className='ms-3' onClick={markAsCompleted} disabled={!denyMarkCompleted}>Mark as Completed</Button>
                {userPrivilegedDownload && <Button variant="primary" className='ms-3' onClick={downloadAll}>Download All</Button>}
                
                {userPrivilegedMessaging &&
                  <ClientChat mToken={mToken} applicationID={applicationID} />
                }
              </Container>
            </div>
          </>}
        </>}

        <Modal className='show-picture' show={showFileModal} onHide={() => {setShowFileModal(false); setFile(''); setExcelFile(null);}} backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {excelFile && <div className='excelTable-wrap'><OutTable data={excelFile.rows} columns={excelFile.cols} tableClassName="table bordered excelTable mb-0 bg-white" tableHeaderRowClass="heading" /></div>}
            {file && <embed src={file} width={file.startsWith("data:image") ? '' : '100%'} height="800" />}
          </Modal.Body>
        </Modal>

        {userPrivilegedReadTPCommunicationLog && <>
          <section>
            <Container fluid>
              <h6>Customer Communication Log</h6>
              <div className='message-table'>
                <table className="table table-log table-striped table-borderless mb-0">
                  <thead>
                    <tr>
                      <th>Sender</th>
                      <th>Message</th>
                      <th>Sent at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tpCommunicationLog && tpCommunicationLog.map((item, index) => {
                      return(
                        <tr key={index}>
                          <td>{item.sender === 'cpa' ? 'tax preparer' : item.sender}</td>
                          <td>{item.message_text}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Container>
          </section>
        </>}

        {userPrivilegedReadApplicationHistory && <>
          <section>
            <Container fluid>
              <h6>Audit Log</h6>
              <table className="table table-log table-striped table-borderless mb-0">
                <thead>
                  <tr>
                    <th>Entity Name</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Updated by</th>
                    <th>Updated at</th>
                  </tr>
                </thead>
                <tbody>
                    {auditData && auditData.sort((a, b) => b.at.localeCompare(a.at)).map((item, index) => {
                      return(
                        <tr key={index}>
                          <td>{item.entity}</td>
                          <td><div dangerouslySetInnerHTML={{__html: item.from}} /></td>
                          <td><div dangerouslySetInnerHTML={{__html: item.to}} /></td>
                          <td>{item.by}</td>
                          <td>{item.at}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Container>
          </section>
        </>}
      </main>
    </>
  );
}

export default ApplicationDetails;
