import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from "axios";

const serverConfig = require("../config/server.js");

const ClientChat = (props) => {

  const [isChatIconExpanded, setIsChatIconExpanded] = useState(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);

  const [messages, setMessages] = useState([]);

  const handleChatWindow = () => {
    setIsChatIconExpanded(!isChatIconExpanded);
    setIsChatWindowOpen(!isChatWindowOpen);
    var objDiv = document.getElementById("chat-content");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const getMessages = () => {
    axios.post(serverConfig.api_base_url + "get_message_list",{token:props.mToken, application_id:props.applicationID})
    .then(response=>{
      console.log('get_message_list response', response);
      if(response.data.status === 200){
        if(response.data.items !== null){
          let msgArray = JSON.parse(response.data.items);
          console.log('get_message_list success', msgArray);
          setMessages(msgArray);

          setTimeout(() => {
            var objDiv = document.getElementById("chat-content");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 100);
        }
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const sendMessage = () => {
    let myMsg = document.getElementById("my-msg").value;
    console.log(myMsg);

    if(myMsg){
      axios.post(serverConfig.api_base_url + "send_message",{token:props.mToken, application_id:props.applicationID, message_text:myMsg})
      .then(response=>{
        console.log('send_message response', response);
        if(response.data.status === 200){
          console.log('send_message success');
          document.getElementById("my-msg").value = '';

          //get messages
          getMessages();
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const handleEnter = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  const handleSendMsg = (e) => {
    e.preventDefault();
    sendMessage();
  }

  useEffect(() => {
    if(props.mToken !== null && props.mToken !== undefined){
      getMessages();
    }
  }, [props.mToken])

  useEffect(() => {
    setTimeout(() => {
      console.log('calling get messages list');
      if(props.mToken !== null && props.mToken !== undefined){
        getMessages();
      }
    }, 100000);
  });

  return (
    <>
      <div className='chat-app'>
        <div className={isChatIconExpanded ? 'chat-icon-wrap expanded' : 'chat-icon-wrap'} onClick={handleChatWindow}>
          <div className='chat-icon'>
            <span><img src={require('./../assets/images/chat-icon.png')} alt="chat icon" width="25" /></span>
          </div>
          <span>SUPPORT MESSENGER {isChatWindowOpen ? <i class="fas fa-angle-down"></i> : <i class="fas fa-angle-up"></i>}</span>
        </div>
        <div className={isChatWindowOpen ? 'chat-window d-flex' : 'chat-window d-none'}>
          <div class='chat-body'>
            <div className='chat-content' id='chat-content'>
              {messages.map((item,key) => {
                return(
                  <>
                    {item.is_my_message === 0 &&
                      <div className='server-msg'>
                        <span className='sender'>{item.sender}</span>
                        <span className='text'>{item.message_text}</span>
                        <span className='timestamp'>{item.created_at.split('.')[0]}</span>
                      </div>
                    }
                    {item.is_my_message === 1 &&
                      <div className='my-msg'>
                        <span className='sender'>{item.sender === 'cpa' ? 'tax preparer' : item.sender}</span>
                        <span className='text'>{item.message_text}</span>
                        <span className='timestamp'>{item.created_at.split('.')[0]}</span>
                      </div>
                    }
                  </>
                )
              })}
            </div>
          </div>
          <div class='chat-message'>
            <Form onSubmit={handleSendMsg}>
              <Form.Group>
                <Form.Control id="my-msg" as="textarea" placeholder="Type here..." onKeyDown={handleEnter} />
              </Form.Group>
              <Button variant="primary" type="submit" className='send'><img src={require('./../assets/images/send-icon.png')} alt="Send" width="25" /></Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientChat;
